<div class="w-full h-full flex flex-wrap justify-between items-center">
  <!-- Left -->
  <div class="flex justify-center lg:justify-start w-full lg:w-1/3">
    <nrc-image
      class="w-10 h-10"
      [source]="{ uri: '../../../assets/icons/logo.svg' }"
      [alt]="'Header logo'"
      [isTransparent]="true"
    />

    <div class="flex justify-between items-center h-10">
      <nrc-virtual-studio-navigator
        class="h-6 w-auto"
        [label]="'Projects'"
        [placeholder]="'Search'"
        [options]="projects"
        [labelProperty]="'translations.0.title'"
        [valueProperty]="'id'"
        [selectedOption]="currentProject"
        [excludedRoutes]="['home', 'projects']"
        (optionChangedEvent)="handleProjectUpdate($event)"
      />
    </div>
  </div>

  <!-- Middle -->
  <div class="flex justify-center items-center space-x-1 md:space-x-3 w-full lg:w-1/3">
    @for (headerItem of headerItems; track $index) {
      <nrc-virtual-studio-header-item
        class="relative h-full p-3 w-auto hover:bg-white/10 hover:cursor-pointer hover:rounded"
        [ngClass]="{
          'pointer-events-none cursor-default': headerItem.disabled
        }"
        [routerLink]=[headerItem.route]
        #rla="routerLinkActive"
        routerLinkActive="active"
        [icon]="headerItem.icon"
        [fillColor]="rla.isActive ? 'cyan' : 'white'"
        [tooltipText]="headerItem.title"
        [isDisabled]="headerItem.disabled"
      />
    }
  </div>

  <!-- Right -->
  <div class="flex justify-center lg:justify-start w-full h-full lg:w-1/3">
    <div class="absolute h-full right-0 top-0 lg:w-1/3 flex items-center justify-end">
      <div
        class="group w-10 h-full flex items-center justify-center hover:bg-[#1F1F1FE6]"
        nrcHoverDelay
        [debounceTimeMouseLeave]="200"
        [targetId]="'legal-pages'"
      >
        <div class="w-full h-full flex justify-center items-center">
          <nrc-icon
            class="w-5 h-5 hover:cursor-pointer"
            [name]="'info-outline'"
          />
        </div>

        <div class="hidden w-40 absolute top-full right-[2.5rem] z-10 bg-[#1F1F1FE6] rounded-b-[0.5rem] text-[#d9d9d9]" id="legal-pages">
          <div class="flex flex-col">
            <!-- Imprint Page router link -->
            <div
              class="flex flex-row justify-between items-center text-sm hover:cursor-pointer hover:bg-white/5 px-4 py-3 border-b border-gray-300 border-opacity-20"
              [routerLink]="'legal/imprint'"
              #rla="routerLinkActive"
              routerLinkActive="active"
            >
              <span>Imprint</span>
              <nrc-icon
                class="w-4 h-4 hover:cursor-pointer"
                [name]="'imprint'"
              />
            </div>

            <!-- Privacy Page router link -->
            <div
              class="flex flex-row justify-between items-center text-sm hover:cursor-pointer hover:bg-white/5 px-4 py-3"
              [routerLink]="'legal/privacy'"
              #rla="routerLinkActive"
              routerLinkActive="active"
            >
              <span>Privacy</span>
              <nrc-icon
                class="w-4 h-4 hover:cursor-pointer"
                [name]="'privacy'"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-10 h-full flex justify-center items-center hover:bg-[#1F1F1FE6]"
        nrcHoverDelay
        [debounceTimeMouseLeave]="200"
        [targetId]="'profile-tooltip'"
      >
        <button
          class="w-7 h-7 max-h-full"
          type="button"
        >
          <div class="bg-violet-500 w-full h-full rounded-full text-xs flex justify-center items-center">
            <span>{{ firstName() | slice:0:1 }}{{ lastName() | slice:0:1 }}</span>
          </div>
        </button>
      </div>

      <nrc-virtual-studio-profile-tooltip
        id="profile-tooltip"
        class="absolute hidden hover:inline-block w-80 max-w-full h-auto top-full min-w-80 right-0 z-50 bg-[#1F1F1FE6] text-sm font-normal tracking-wide shadow-sm rounded-bl-[10px]"
        (logoutEvent)="logoutEvent.emit()"
      />
    </div>
  </div>
</div>
