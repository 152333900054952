export interface IFilterPropertyOption {
  propertyLabel: string;
  propertyName: string;
  type: FilterPropertyType;
  rules: FilterRuleType[];
  isRequired?: boolean;
  description?: string;
  relatedCollection?: string;
}

export interface IFilterConditionPreset {
  collection: string;
  id: string;
  filterOptions: IFilterPropertyOption[];
}

export type StringFilterRule = 'equals' | 'in' | 'notIn' | 'lt' | 'lte' | 'gt' | 'gte' | 'contains' | 'startsWith' | 'endsWith';
export type NumberFilterRule = 'equals' | 'in' | 'notIn' | 'lt' | 'lte' | 'gt' | 'gte';
export type DateFilterRule = 'equals' | 'in' | 'notIn' | 'lt' | 'lte' | 'gt' | 'gte';
export type BooleanFilterRule = 'equals';
export type RelationFilterRule = 'every' | 'some' | 'none' | 'is' | 'isNot';
export type LogicalOperator = 'AND' | 'OR' | 'NOT';

export type FilterRuleType = StringFilterRule | NumberFilterRule | DateFilterRule | BooleanFilterRule | RelationFilterRule;
export type FilterPropertyType = 'string' | 'number' | 'boolean' | 'date' | 'relation';

// Constants matching Prisma's filter operations
export const STRING_RULES: StringFilterRule[] = ['equals', 'in', 'notIn', 'lt', 'lte', 'gt', 'gte', 'contains', 'startsWith', 'endsWith'];
export const NUMBER_RULES: NumberFilterRule[] = ['equals', 'in', 'notIn', 'lt', 'lte', 'gt', 'gte'];
export const DATE_RULES: DateFilterRule[] = ['equals', 'in', 'notIn', 'lt', 'lte', 'gt', 'gte'];
export const BOOLEAN_RULES: BooleanFilterRule[] = ['equals'];
export const RELATION_RULES: RelationFilterRule[] = ['every', 'some', 'none', 'is', 'isNot'];
