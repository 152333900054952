import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, Signal, computed, effect } from '@angular/core';
import { Subject, catchError, finalize, takeUntil } from 'rxjs';
import { ActionRole } from '@newroom-connect/library/enums';
import { JwtHelper } from '@newroom-connect/library/helpers';
import { IProject } from '@newroom-connect/library/interfaces';
import { LocalStorageService, LoggingService, ProjectErrorHandlerService, ProjectService, SessionService, StateService } from '@newroom-connect/library/services';

export interface IHeaderItem {
  title: string;
  icon: string;
  route: string;
  disabled?: boolean;
}

@Component({
  selector: 'nrc-virtual-studio-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() public height: number = 0;

  @Input() public headerItems: IHeaderItem[] = [];

  @Output() public logoutEvent = new EventEmitter<void>();

  public actionRole = ActionRole;

  public firstName: Signal<string>;
  public lastName: Signal<string>;

  public projects: IProject[] = [];
  public currentProjectPage = 1; // Used for infinite scroll to load more projects, if requested.
  public currentProject?: IProject | null;

  private destroy$ = new Subject<void>();
  private areProjectsLoaded = false; // Indicates whether the projects already have been loaded to not load them again.

  /**
   * @constructor
   *
   * @param sessionService
   * @param loggingService
   * @param stateService
   * @param projectService
   * @param projectErrorHandlerService
   * @param localStorageService
   */
  constructor(
    private readonly sessionService: SessionService,
    private readonly loggingService: LoggingService,
    private readonly stateService: StateService,
    private readonly projectService: ProjectService,
    private readonly projectErrorHandlerService: ProjectErrorHandlerService,
    private readonly localStorageService: LocalStorageService
  ) {
    this.firstName = computed(() => JwtHelper.getValueFromToken('profile:firstName', this.sessionService.watchUserSession()()?.getIdToken()) ?? '');

    this.lastName = computed(() => JwtHelper.getValueFromToken('profile:lastName', this.sessionService.watchUserSession()()?.getIdToken()) ?? '');

    // Load projects, if projects have not been loaded and a valid user session is present.
    effect(() => {
      const userSession = this.sessionService.getUserSession();

      if (!this.areProjectsLoaded && userSession?.isValid()) {
        setTimeout(() => {
          this.loadProjects();
        }, 0);
      }
    });

    // Update the current selected project in the project search bar if the current project changes.
    effect(() => {
      const currentProject = this.projectService.watchCurrentProject()();

      if (currentProject && currentProject.id === this.currentProject?.id) {
        return;
      }

      this.currentProject = currentProject ? { ...currentProject } : null;
    });
  }

  /**
   *
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Load projects from the backend.
   *
   * @param pageSize The page size of projects to request from the backend.
   */
  public loadProjects(pageSize = 100): void {
    this.loggingService.debug(`Load projects from page ${this.currentProjectPage} with page size ${pageSize}...`);

    this.stateService.setLoadingState(true);

    this.projectService.listProjects({
      pagination: { page: this.currentProjectPage, pageSize }
    }).pipe(
      takeUntil(this.destroy$),
      catchError(() => this.projectErrorHandlerService.handleListError()),
      finalize(() => this.stateService.setLoadingState(false))
    ).subscribe(projectListResponse => {
      this.loggingService.debug('Project list response: ', projectListResponse);

      this.projects = projectListResponse.data;

      // If the project list contains the stored project ID from the local storage, pre-select this project.
      const currentProjectIdCached = this.localStorageService.get('currentProjectId');

      this.currentProject = currentProjectIdCached
        ? this.projects.find(project => project.id === currentProjectIdCached) ?? null
        : this.projects[0] ?? null;

      this.projectService.setCurrentProject(this.currentProject);

      this.areProjectsLoaded = true;
    });
  }

  /**
   *
   * @param event
   */
  public handleProjectUpdate(event: IProject): void {
    this.projectService.setCurrentProject(event);
  }
}
