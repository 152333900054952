<div class="flex flex-col w-full h-full overflow-hidden grow rounded-10">
  <!-- Media Library top header -->
  <div class="flex w-full p-3 bg-white/10 border-b-1 border-b-neutral-600">
    @if (title) {
      <span class="text-sm font-semibold leading-tight tracking-wider text-white"> {{title}} </span>
    }
  </div>

  <div class="flex w-full h-full">
    <div class="flex flex-col grow bg-white/5 min-h-[30rem]">
      <div class="flex items-center justify-center w-full border-b-1 border-b-neutral-600">
        <nrc-media-library-header
          class="flex w-full h-12"
          [label]="'Start'"
          [actionButtons]="headerActionButtons()"
          [navigationHistory]="mediaLibraryService.headerNavigationHistory()"
          [canNavigateBack]="mediaLibraryService.canNavigateBack()"
          (backButtonClickEvent)="mediaLibraryService.navigateBack()"
          (navigateToLevelEvent)="mediaLibraryService.navigateToLevel($event)"
          (actionButtonClickEvent)="handleHeaderActionButtonEvent($event)"
        />
        <div class="flex items-center justify-center w-4 h-7 right-10 border-1 rounded-l-md border-neutral-600">
          <nrc-button
            class="relative flex w-6 h-6 transition-transform duration-250"
            [ngClass]="{
              'rotate-120': mediaLibraryService.isPropertiesCollapsed(),
              'rotate-180': !mediaLibraryService.isPropertiesCollapsed(),
            }"
            [icon]="'arrow-back'"
            [buttonType]="buttonType.ICON"
            [role]="actionRole.TRANSPARENT_SECONDARY"
            [isRound]="false"
            [disabled]="!mediaLibraryService.selectedCardItem()"
            (clickEvent)="mediaLibraryService.togglePropertiesCollapsed()"
          />
        </div>
      </div>

      @if (mediaLibraryService.openedCard().controls.length > 0) {
        <div
          class="flex items-center justify-between w-full px-4 py-2"
          [ngClass]="{
            'justify-between ': isItemSelectionActive(),
            'justify-end ': !isItemSelectionActive(),
          }"
        >
          <div
            class="flex items-center justify-center -translate-x-4"
            [ngClass]="{
              'visible': isItemSelectionActive(),
              'hidden': !isItemSelectionActive()
            }"
          >

            <nrc-checkbox
              [checkStatus]="mediaLibraryService.isEveryPageItemSelected()  ? 'checked' : (mediaLibraryService.isSomeButNotEveryPageItemSelected() ? 'semi-checked' : 'unchecked')"
              (clickEvent)="toggleAllItemsIsSelected()"
            />

            <span class="text-sm text-white -translate-x-2">All</span>
          </div>

          <nrc-button
            [label]="isItemSelectionActive() ? 'Deselect items' : 'Select items'"
            [textSize]="'xs'"
            [icon]="isItemSelectionActive() ? 'multi-check-off' : 'multi-check'"
            [role]="actionRole.SECONDARY"
            (clickEvent)="toggleItemSelection()"
          />
        </div>
      }

      <div
        #gridContainer
        class="p-4 grid gap-4 transform transition-all duration-500 max-h-[25rem] overflow-auto"
        [ngClass]="{
          'grid-cols-1': mediaLibraryService.openedCard().controls.length === 0,
          'grid-cols-2': mediaLibraryService.openedCard().controls.length > 0 && mediaLibraryService.openedCard().controls.length <= 4,
          'grid-cols-3': mediaLibraryService.openedCard().controls.length > 4 && !mediaLibraryService.isPropertiesCollapsed(),
          'grid-cols-4': mediaLibraryService.openedCard().controls.length > 4 && mediaLibraryService.isPropertiesCollapsed()
        }"
        nrcGridLayoutAnimation
        [config]="{duration: 300, easing: 'easeInOut'}"
      >
        @if (mediaLibraryService.openedCard().controls.length === 0) {
          <div class="flex items-center justify-center w-full p-8">
            <nrc-media-library-empty
              class="w-1/2 "
              [canCreateCards]="this.canCreateCard()"
              (actionButtonClicked)="handleEmptyCardActionEvent()"
            />
          </div>
        } @else {
          @for (item of mediaLibraryService.openedCard().controls; track item; let i = $index) {
            <nrc-media-library-card-item
              class="transform rounded-md cursor-pointer"
              [showCheckbox]="isItemSelectionActive()"
              [selectedItem]="item.value"
              [projectId]="projectId"
              [index]="i"
              [isFolderCard]="item.controls.type.value === mediaType.CARD"
              (itemClickEvent)="handleCardItemClickEvent(i)"
              (openCardEvent)="handleOpenCardEvent(i)"
            />
          }
        }
      </div>
    </div>

    <div
      class="transition-margin-top duration-250 w-[30%] max-w-[30%]"
      [@collapseAnimation]="{
        value: mediaLibraryService.isPropertiesCollapsed() || !mediaLibraryService.selectedCardItem() ? 'closed' : 'open',
        params: { width: mediaLibraryService.isPropertiesCollapsed() || !mediaLibraryService.selectedCardItem() ? '0' : '*' }
      }"
    >
      @if (mediaLibraryService.selectedCardItem()) {
        <nrc-media-library-item-properties
          [apiBaseUrl]="apiBaseUrl"
          [projectId]="projectId"
          [itemIndex]="mediaLibraryService.selectedCardItem()?.index ?? -1"
          [itemValue]="mediaLibraryService.selectedCardItem()?.value ?? null"
          (valueChange)="handlePropertiesChangeEvent($event.index, $event.value)"
        />
      }
    </div>
  </div>
</div>

@if (isFileLibraryVisible() && projectId) {
  <nrc-input-file
    [projectId]="projectId"
    [apiBaseUrl]="apiBaseUrl"
    [formControl]="fileLibraryFormControl"
    [fileLibraryModalOnly]="true"
    (fileChangedEvent)="handleFileLibraryModalCloseEvent($event)"
  />
}