import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, effect, ElementRef, Input, Output, EventEmitter, OnChanges, OnDestroy, signal, SimpleChanges, ViewChild } from '@angular/core';
import { MediaSource } from '@newroom-connect/library/types';
import { ArrayHelper } from '@newroom-connect/library/helpers';

import { LoadingSpinnerComponent } from '../loading/loading-spinner/loading-spinner.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    LoadingSpinnerComponent
  ],
  selector: 'nrc-video',
  templateUrl: './video.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input({ required: true }) public sources: MediaSource[] = [];
  @Input() public controls = true;
  @Input() public autoplay = false;
  @Input() public muted = false;
  @Input() public loop = false;
  @Input() public playsInline = false;
  @Input() public disablePictureInPicture = false;
  @Input() public objectFit: 'cover' | 'contain' = 'contain';
  @Output() public loadComplete = new EventEmitter<void>();

  @ViewChild('videoPlayer', { static: false }) public videoPlayerRef!: ElementRef<HTMLVideoElement>;
  public hasLoadedData = signal<boolean>(false);

  /**
   * @constructor
   *
   */
  constructor() {
    effect(() => {
      if (!this.videoPlayerRef || !ArrayHelper.isNotEmpty(this.sources)) {
        return;
      }

      this.initializeVideo();
    }, { allowSignalWrites: true });
  }

  /**
   * We call the load method of the video element on changing the sources of the component, so that the element is updated
   * with the new sources.
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['sources'] && !changes['sources'].firstChange && this.videoPlayerRef) {
      this.initializeVideo();
    }
  }

  /**
   *
   */
  public ngAfterViewInit(): void {
    if (!this.sources.length || !ArrayHelper.isNotEmpty(this.sources)) {
      return;
    }

    this.initializeVideo();
  }

  /**
   *
   */
  public ngOnDestroy(): void {
    this.hasLoadedData.set(false);
  }

  /**
   *
   */
  private initializeVideo(): void {
    const video = this.videoPlayerRef.nativeElement;

    // Reset the loaded state when sources change
    this.hasLoadedData.set(false);

    // Add listeners
    video.addEventListener('error', (): void => this.hasLoadedData.set(false));

    video.addEventListener('loadeddata', (): void => {
      this.hasLoadedData.set(true);
      this.loadComplete.emit();
    });

    // Force reload of video
    video.load();
  }
}
