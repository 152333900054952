import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { take } from 'rxjs';
import { DoubleClickDirective } from '@newroom-connect/library/directives';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';
import { IFileHydrated, MediaItem } from '@newroom-connect/library/interfaces';
import { FileService } from '@newroom-connect/library/services';

import { CheckboxComponent } from '../../checkbox/checkbox.component';
import { MediaPreviewComponent } from '../../media-preview/media-preview.component';
import { DestroyableComponent } from '../../abstract/destroyable/destroyable.component';
import { ButtonComponent } from '../../buttons';

@Component({
  selector: 'nrc-media-library-card-item',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MediaPreviewComponent,
    CheckboxComponent,
    ButtonComponent,
    DoubleClickDirective
  ],
  templateUrl: './media-library-card-item.component.html'
})
export class MediaLibraryCardItemComponent extends DestroyableComponent implements OnInit, OnChanges {
  private readonly fileService = inject(FileService);

  @Input({ required: true }) public projectId!: string;
  @Input({ required: true }) public index!: number;

  @Input() public selectedItem: Partial<MediaItem> | null = null;
  @Input() public isFolderCard = true;
  @Input() public showCheckbox?: boolean;

  @Output() public itemClickEvent = new EventEmitter<{ index: number }>;
  @Output() public openCardEvent = new EventEmitter<{ index: number }>;

  public buttonType = ButtonType;
  public actionRole = ActionRole;

  public readonly thumbnailFile = signal<IFileHydrated | null>(null);
  public readonly fileItem = signal<IFileHydrated | null>(null);
  public readonly thumbnailFileSource = signal<string>('');
  public readonly fileItemSource = signal<string>('');

  public readonly isSelected = signal<boolean>(false);

  /**
   *
   */
  public ngOnInit(): void {
    if (!this.selectedItem) {
      return;
    }

    this.updateItemData(this.selectedItem);
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedItem']?.currentValue && changes['selectedItem']?.currentValue !== changes['selectedItem']?.previousValue) {
      const current = changes['selectedItem'].currentValue;
      const previous = changes['selectedItem'].previousValue;

      this.isSelected.set(current.isSelected ?? false);

      // Only update if IDs or tags changed to avoid unnecessary API calls
      const shouldUpdate =
        current?.thumbnailId !== this.thumbnailFile()?.id ||
        ('fileId' in current && current.fileId !== this.fileItem()?.id) ||
        ('fileTags' in current && JSON.stringify(current.fileTags) !== JSON.stringify(previous?.fileTags));

      if (!shouldUpdate) {
        return;
      }

      this.updateItemData(current);
    }
  }

  /**
   *
   * @param index
   */
  public handleItemClickEvent(index: number): void {
    this.itemClickEvent.emit({ index });
  }

  /**
   *
   * @param index
   */
  public handleOpenCardEvent(index: number): void {
    this.openCardEvent.emit({ index });
  }

  /**
   * Updates all item data when changes occur.
   *
   * @param item
   */
  private updateItemData(item: Partial<MediaItem>): void {
    if (!item) {
      return;
    }

    if (item.thumbnailId) {
      this.fileService.getFile(this.projectId, item.thumbnailId)
        .pipe(take(1))
        .subscribe(file => {
          this.thumbnailFile.set(file);
          this.thumbnailFileSource.set(file.source);
        });
    } else {
      this.thumbnailFile.set(null);
      this.thumbnailFileSource.set('');
    }

    if ('fileId' in item && item.fileId) {
      this.fileService.getFile(this.projectId, item.fileId)
        .pipe(take(1))
        .subscribe(file => {
          this.fileItem.set(file);
          this.fileItemSource.set(file.source);
        });
    } else {
      this.fileItem.set(null);
      this.fileItemSource.set('');
    }
  }
}
