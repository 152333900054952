<div class="flex flex-col h-full border-l-1 border-l-neutral-600 p-4 bg-white/10">
  <span class="text-white/70 text-sm pb-4"> Properties </span>
  @if (projectId && propertiesForm) {
    <form [formGroup]="propertiesForm"  class="flex flex-col gap-4">
      <nrc-input-text
        class="w-full"
        [formControl]="propertiesForm.controls.label | formControl"
        [value]="label()"
        [type]="'text'"
        [name]="'card-item-label'"
        [id]="'card-item-label'"
        [label]="'COMPONENTS.FILE_REPLACER.INPUTS.TITLE.LABEL' | translate"
      />

      @if (type() === 'file' && propertiesForm.controls.fileId && propertiesForm.controls.fileTags) {
        <div class="flex flex-col gap-4" #overflowParentElement>
          <nrc-input-file
            [projectId]="projectId"
            [label]="'File'"
            [apiBaseUrl]="apiBaseUrl"
            [formControl]="propertiesForm.controls.fileId | formControl"
            [size]="inputFileComponentSize.SMALL"
            (fileChangedEvent)="handleFileChangeEvent($event)"
          />

          <div class="flex flex-col gap-1">
            <nrc-input-select
              class="max-w-full"
              [formControl]="propertiesForm.controls.fileTags | formControl"
              [multiple]="true"
              [name]="'project-detail-translations'"
              [id]="'project-detail-translations'"
              [label]="'File Tags'"
              [placeholder]="'Search or add new..'"
              [options]="projectFileTags()"
              [allowAddingOptions]="true"
            />

            <nrc-note
              class="w-full"
              [message]="{
                message: 'Updating file tags will take affect globally.',
                level: level.WARN
              }"
            />
          </div>
        </div>

        <hr class="w-full h-[2px] bg-white/5 border-none">
      }

      <nrc-input-file
        [projectId]="projectId"
        [label]="'Thumbnail'"
        [apiBaseUrl]="apiBaseUrl"
        [formControl]="propertiesForm.controls.thumbnailId | formControl"
        [size]="inputFileComponentSize.SMALL"
        (fileChangedEvent)="handleThumbnailChangeEvent($event)"
      />

      @if (propertiesForm.controls.isThumbnailAsDetailImage && type() === 'card') {
        <nrc-input-checkbox
          class="mt-2"
          [formControl]="propertiesForm.controls.isThumbnailAsDetailImage | formControl"
          [name]="'animation-infinite'"
          [id]="'animation-infinite'"
          [label]="'Thumbnail as detail image'"
        />
      }
    </form>
  }
</div>