import { Directive, ElementRef, Input, OnDestroy, Renderer2, inject, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { fromEvent, merge, Subscription, switchMap, takeUntil } from 'rxjs';
import { LoggingService } from '@newroom-connect/library/services';

interface IGuideLineSet {
  guide: HTMLElement;
  measurement: IMeasurementGuide;
}

interface IMeasurementGuide {
  line: HTMLElement;
  label: HTMLElement;
}

interface IGuideLines {
  top: IGuideLineSet;
  right: IGuideLineSet;
  bottom: IGuideLineSet;
  left: IGuideLineSet;
}

@Directive({
  selector: '[nrcAlignmentGuide]',
  standalone: true
})
export class AlignmentGuideDirective implements AfterViewInit, OnDestroy, OnChanges {
  @Input({ required: true }) public targetContainerRef!: ElementRef<HTMLElement>;
  @Input() public snapThreshold = 10; // Distance in pixels to show guidelines
  @Input() public guideColor = '#017e95'; // Guide line color

  private guides: HTMLElement[] = [];

  private container?: HTMLElement;
  private element?: HTMLElement;
  private guideLines?: IGuideLines;

  private visibleGuides = {
    vertical: 'top' as 'top' | 'bottom',
    horizontal: 'left' as 'left' | 'right'
  };

  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  private readonly logger = inject(LoggingService);

  private subscriptions = new Subscription();

  /**
   */
  public ngAfterViewInit(): void {
    this.element = this.elementRef.nativeElement;

    if (!this.element || !this.targetContainerRef) {
      return;
    }

    if (!this.targetContainerRef) {
      return;
    }

    this.container = this.targetContainerRef.nativeElement;

    this.guideLines = {
      top: this.createGuideLine('horizontal'),
      right: this.createGuideLine('vertical'),
      bottom: this.createGuideLine('horizontal'),
      left: this.createGuideLine('vertical')
    };

    this.createGuideLines();
    this.setupEventListeners();
  }

  /**
   * Handles changes to the directive's input properties.
   *
   * @param changes SimpleChanges object containing current and previous property values.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['targetContainerRef'] && changes['targetContainerRef'].currentValue && this.element) {
      this.container = this.targetContainerRef.nativeElement;

      this.guideLines = {
        top: this.createGuideLine('horizontal'),
        right: this.createGuideLine('vertical'),
        bottom: this.createGuideLine('horizontal'),
        left: this.createGuideLine('vertical')
      };

      this.createGuideLines();
      this.setupEventListeners();
    }
  }

  /**
   */
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    this.removeGuideLines();
  }

  /**
   * Creates and initializes the four guide lines (top, right, bottom, left).
   *
   */
  private createGuideLines(): void {
    if (!this.container || !this.guideLines) {
      this.logger.error('Failed to append guide lines to the view. No parent container found.', this.container, this.guideLines);

      return;
    }

    for (const guideLine of Object.values(this.guideLines)) {
      this.renderer.appendChild(this.container, guideLine.guide);
      this.guides.push(guideLine.guide);

      this.renderer.appendChild(this.container, guideLine.measurement.label);
      this.guides.push(guideLine.measurement.label);

      this.renderer.appendChild(this.container, guideLine.measurement.line);
      this.guides.push(guideLine.measurement.line);
    }
  }

  /**
   * Creates a single guide line element with the specified orientation.
   *
   * @param orientation The orientation of the guide line ('horizontal' | 'vertical').
   *
   * @returns HTMLElement - The created guide line element.
   */
  private createGuideLine(orientation: 'horizontal' | 'vertical'): IGuideLineSet {
    const guide = this.renderer.createElement('div');
    const measurement = this.createMeasurementGuide(orientation);

    this.renderer.setStyle(guide, 'position', 'absolute');
    this.renderer.setStyle(guide, 'pointer-events', 'none');
    this.renderer.setStyle(guide, 'z-index', '60');
    this.renderer.setStyle(guide, 'opacity', '0');
    this.renderer.setStyle(guide, 'transition', 'opacity 0.2s');

    if (orientation === 'horizontal') {
      this.renderer.setStyle(guide, 'height', '0.1rem');
      this.renderer.setStyle(guide, 'width', '100%');
      this.renderer.setStyle(guide, 'border-top', `0.1rem dashed ${this.guideColor}`);
    } else {
      this.renderer.setStyle(guide, 'width', '0.1rem');
      this.renderer.setStyle(guide, 'height', '100%');
      this.renderer.setStyle(guide, 'border-left', `0.1rem dashed ${this.guideColor}`);
    }

    return {
      guide,
      measurement
    };
  }

  /**
   * Creates a measurement guide with line and label elements.
   *
   * @param orientation The orientation of the measurement guide ('horizontal' | 'vertical').
   * @returns MeasurementGuide - The created measurement guide elements.
   */
  private createMeasurementGuide(orientation: 'horizontal' | 'vertical'): IMeasurementGuide {
    const line = this.renderer.createElement('div');
    const label = this.renderer.createElement('div');

    // Style the measurement line
    this.renderer.setStyle(line, 'position', 'absolute');
    this.renderer.setStyle(line, 'pointer-events', 'none');
    this.renderer.setStyle(line, 'z-index', '59');
    this.renderer.setStyle(line, 'opacity', '0');
    this.renderer.setStyle(line, 'transition', 'opacity 0.2s');
    this.renderer.setStyle(line, 'background-color', this.guideColor);

    if (orientation === 'vertical') {
      this.renderer.setStyle(line, 'width', '1px');
      this.renderer.setStyle(line, 'transform-origin', 'top');
    } else {
      this.renderer.setStyle(line, 'height', '1px');
      this.renderer.setStyle(line, 'transform-origin', 'left');
    }

    // Style the measurement label
    this.renderer.setStyle(label, 'position', 'absolute');
    this.renderer.setStyle(label, 'pointer-events', 'none');
    this.renderer.setStyle(label, 'z-index', '61');
    this.renderer.setStyle(label, 'opacity', '0');
    this.renderer.setStyle(label, 'transition', 'opacity 0.2s');
    this.renderer.setStyle(label, 'background-color', `${this.guideColor}`);
    this.renderer.setStyle(label, 'color', 'white');
    this.renderer.setStyle(label, 'padding', '2px 4px');
    this.renderer.setStyle(label, 'border-radius', '2px');
    this.renderer.setStyle(label, 'font-size', '0.75rem');
    this.renderer.setStyle(label, 'white-space', 'nowrap');

    return { line, label };
  }

  /**
   * Sets up mouse and touch event listeners for guide line visibility and positioning.
   *
   */
  private setupEventListeners(): void {
    if (!this.element) {
      return;
    }

    // Create observables for start events (mousedown, touchstart)
    const mouseStart$ = fromEvent<MouseEvent>(this.element, 'mousedown');
    const touchStart$ = fromEvent<TouchEvent>(this.element, 'touchstart');
    const start$ = merge(mouseStart$, touchStart$);

    // Create observables for move events (mousemove, touchmove)
    const mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove');
    const touchMove$ = fromEvent<TouchEvent>(document, 'touchmove');
    const move$ = merge(mouseMove$, touchMove$);

    // Create observables for end events (mouseup, touchend)
    const mouseEnd$ = fromEvent<MouseEvent>(document, 'mouseup');
    const touchEnd$ = fromEvent<TouchEvent>(document, 'touchend');
    const end$ = merge(mouseEnd$, touchEnd$);

    // Subscribe to the start events and handle the stream
    const dragSubscription = start$.pipe(
      switchMap(() => {
        // Show guides when user holds click but don't drag.
        this.showGuides();

        // Return move events until end event occurs
        return move$.pipe(
          takeUntil(end$)
        );
      })
    ).subscribe(() => {
      // Update positions during drag
      this.updateGuidePositions();
      this.showGuides();
    });

    // Handle end events separately to hide guides
    const endSubscription = end$.subscribe(() => {
      this.hideGuides();
    });

    // Add subscriptions to be cleaned up later
    this.subscriptions.add(dragSubscription);
    this.subscriptions.add(endSubscription);
  }

  /**
   * Shows all guide lines by setting their opacity to 1.
   *
   */
  private showGuides(): void {
    if (!this.element || !this.container || !this.guideLines) {
      this.logger.error('Failed to show guide lines. Missing required elements.');
      return;
    }

    const rect = this.element.getBoundingClientRect();
    const containerRect = this.container.getBoundingClientRect();

    // Calculate distances to edges
    const topDistance = rect.top - containerRect.top;
    const bottomDistance = containerRect.bottom - rect.bottom;
    const leftDistance = rect.left - containerRect.left;
    const rightDistance = containerRect.right - rect.right;

    // Determine which guides to show based on proximity
    this.visibleGuides = {
      vertical: topDistance <= bottomDistance ? 'top' : 'bottom',
      horizontal: leftDistance <= rightDistance ? 'left' : 'right'
    };

    // Set visibility based on proximity
    for (const [position, guideLine] of Object.entries(this.guideLines)) {
      const { guide, measurement } = guideLine;
      const shouldShow = (
        (position === this.visibleGuides.vertical && (position === 'top' || position === 'bottom')) ||
        (position === this.visibleGuides.horizontal && (position === 'left' || position === 'right'))
      );

      const opacity = shouldShow ? '1' : '0';

      this.renderer.setStyle(guide, 'opacity', 1);
      this.renderer.setStyle(measurement.line, 'opacity', opacity);
      this.renderer.setStyle(measurement.label, 'opacity', opacity);
    }
  }

  /**
   * Hides all guide lines by setting their opacity to 0.
   *
   */
  private hideGuides(): void {
    if (!this.guideLines) {
      this.logger.error('Failed to hide guide lines. Could not determine guide lines.');

      return;
    }

    for (const guideLine of Object.values(this.guideLines)) {
      this.renderer.setStyle(guideLine.guide, 'opacity', '0');
      this.renderer.setStyle(guideLine.measurement.line, 'opacity', '0');
      this.renderer.setStyle(guideLine.measurement.label, 'opacity', '0');
    }
  }

  /**
   * Updates the positions of all guide lines and measurements based on the current element position.
   */
  private updateGuidePositions(): void {
    if (!this.element || !this.container || !this.guideLines) {
      return;
    }

    const rect = this.element.getBoundingClientRect();
    const containerRect = this.container.getBoundingClientRect();

    const top = ((rect.top - containerRect.top) / containerRect.height) * 100;
    const left = ((rect.left - containerRect.left) / containerRect.width) * 100;
    const bottom = top + (rect.height / containerRect.height) * 100;
    const right = left + (rect.width / containerRect.width) * 100;

    const baselineHeight = 1080;
    const baselineWidth = 1920;

    for (const [position, guideLine] of Object.entries(this.guideLines)) {
      const { guide, measurement } = guideLine;

      if (!measurement) {
        continue;  // Changed from return to continue
      }

      this.updateMeasurementForPosition(
        position as 'top' | 'right' | 'bottom' | 'left',
        { guide, measurement },
        { rect, containerRect },
        { top, right, bottom, left },
        { baselineHeight, baselineWidth }
      );
    }
  }

  /**
   * Updates measurements for a specific guide line position.
   *
   * @param position The position of the guide line.
   * @param elements The guide line elements to update.
   * @param elements.guide
   * @param elements.measurement
   * @param rects The bounding rectangles of the element and container.
   * @param rects.rect
   * @param rects.containerRect
   * @param coordinates The calculated percentage positions.
   * @param coordinates.top
   * @param coordinates.right
   * @param coordinates.bottom
   * @param coordinates.left
   * @param baseline The baseline dimensions for scaling.
   * @param baseline.baselineHeight
   * @param baseline.baselineWidth
   */
  private updateMeasurementForPosition(
    position: 'top' | 'right' | 'bottom' | 'left',
    elements: { guide: HTMLElement; measurement: IMeasurementGuide },
    rects: { rect: DOMRect; containerRect: DOMRect },
    coordinates: { top: number; right: number; bottom: number; left: number },
    baseline: { baselineHeight: number; baselineWidth: number }
  ): void {
    const { guide, measurement: { line, label } } = elements;
    const { rect, containerRect } = rects;
    const { top, right, bottom, left } = coordinates;
    const { baselineHeight, baselineWidth } = baseline;

    const isVertical = position === 'left' || position === 'right';
    const resolutionSuffix = isVertical ? '1920p' : '1080p';

    // Clear previous content
    while (label.firstChild) {
      label.removeChild(label.firstChild);
    }

    // Create new spans for each update
    const pixelSpan = this.renderer.createElement('span');
    const baselineSpan = this.renderer.createElement('span');

    this.renderer.setStyle(pixelSpan, 'font-size', '0.6rem');

    // Style the baseline span
    this.renderer.setStyle(baselineSpan, 'font-size', '0.5rem');
    this.renderer.setStyle(baselineSpan, 'opacity', '0.9');

    switch (position) {
      case 'top': {
        const pixelDistance = rect.top - containerRect.top;
        const baselineDistance = (pixelDistance / containerRect.height) * baselineHeight;

        this.renderer.setStyle(guide, 'top', `${top}%`);
        this.renderer.setStyle(line, 'top', '0');
        this.renderer.setStyle(line, 'left', `${left + (rect.width / containerRect.width * 50)}%`);
        this.renderer.setStyle(line, 'height', `${top}%`);
        this.renderer.setStyle(line, 'width', '1px');
        this.renderer.setStyle(line, 'border', 'none');
        this.renderer.setStyle(line, 'background', this.guideColor);

        this.renderer.setStyle(label, 'top', `${top / 2}%`);
        this.renderer.setStyle(label, 'left', `${left + (rect.width / containerRect.width * 50)}%`);
        this.renderer.setStyle(label, 'transform', 'translate(-50%, -50%)');
        this.renderer.setStyle(label, 'display', 'flex');
        this.renderer.setStyle(label, 'flex-direction', 'column');

        this.renderer.setProperty(pixelSpan, 'textContent', `${Math.round(pixelDistance)}px`);
        this.renderer.setProperty(baselineSpan, 'textContent', `${Math.round(baselineDistance)}px@${resolutionSuffix}`);

        this.renderer.appendChild(label, pixelSpan);
        this.renderer.appendChild(label, baselineSpan);
        break;
      }
      case 'right': {
        const pixelDistance = containerRect.right - rect.right;
        const baselineDistance = (pixelDistance / containerRect.width) * baselineWidth;

        this.renderer.setStyle(guide, 'left', `${right}%`);
        this.renderer.setStyle(line, 'left', `${right}%`);
        this.renderer.setStyle(line, 'top', `${top + (rect.height / containerRect.height * 50)}%`);
        this.renderer.setStyle(line, 'width', `${100 - right}%`);
        this.renderer.setStyle(line, 'height', '1px');
        this.renderer.setStyle(line, 'border', 'none');
        this.renderer.setStyle(line, 'background', this.guideColor);

        this.renderer.setStyle(label, 'left', `${(right + 100) / 2}%`);
        this.renderer.setStyle(label, 'top', `${top + (rect.height / containerRect.height * 50)}%`);
        this.renderer.setStyle(label, 'transform', 'translate(-50%, -50%)');
        this.renderer.setStyle(label, 'display', 'flex');
        this.renderer.setStyle(label, 'flex-direction', 'column');

        this.renderer.setProperty(pixelSpan, 'textContent', `${Math.round(pixelDistance)}px`);
        this.renderer.setProperty(baselineSpan, 'textContent', `${Math.round(baselineDistance)}px@${resolutionSuffix}`);

        this.renderer.appendChild(label, pixelSpan);
        this.renderer.appendChild(label, baselineSpan);

        break;
      }
      case 'bottom': {
        const pixelDistance = containerRect.bottom - rect.bottom;
        const baselineDistance = (pixelDistance / containerRect.height) * baselineHeight;

        this.renderer.setStyle(guide, 'top', `${bottom}%`);
        this.renderer.setStyle(line, 'top', `${bottom}%`);
        this.renderer.setStyle(line, 'left', `${left + (rect.width / containerRect.width * 50)}%`);
        this.renderer.setStyle(line, 'height', `${100 - bottom}%`);
        this.renderer.setStyle(line, 'width', '1px');
        this.renderer.setStyle(line, 'border', 'none');
        this.renderer.setStyle(line, 'background', this.guideColor);

        this.renderer.setStyle(label, 'top', `${(bottom + 100) / 2}%`);
        this.renderer.setStyle(label, 'left', `${left + (rect.width / containerRect.width * 50)}%`);
        this.renderer.setStyle(label, 'transform', 'translate(-50%, -50%)');
        this.renderer.setStyle(label, 'display', 'flex');
        this.renderer.setStyle(label, 'flex-direction', 'column');

        this.renderer.setProperty(pixelSpan, 'textContent', `${Math.round(pixelDistance)}px`);
        this.renderer.setProperty(baselineSpan, 'textContent', `${Math.round(baselineDistance)}px@${resolutionSuffix}`);

        this.renderer.appendChild(label, pixelSpan);
        this.renderer.appendChild(label, baselineSpan);
        break;
      }
      case 'left': {
        const pixelDistance = rect.left - containerRect.left;
        const baselineDistance = (pixelDistance / containerRect.width) * baselineWidth;

        this.renderer.setStyle(guide, 'left', `${left}%`);
        this.renderer.setStyle(line, 'left', '0');
        this.renderer.setStyle(line, 'top', `${top + (rect.height / containerRect.height * 50)}%`);
        this.renderer.setStyle(line, 'width', `${left}%`);
        this.renderer.setStyle(line, 'height', '1px');
        this.renderer.setStyle(line, 'border', 'none');
        this.renderer.setStyle(line, 'background', this.guideColor);

        this.renderer.setStyle(label, 'left', `${left / 2}%`);
        this.renderer.setStyle(label, 'top', `${top + (rect.height / containerRect.height * 50)}%`);
        this.renderer.setStyle(label, 'transform', 'translate(-50%, -50%)');
        this.renderer.setStyle(label, 'display', 'flex');
        this.renderer.setStyle(label, 'flex-direction', 'column');

        this.renderer.setProperty(pixelSpan, 'textContent', `${Math.round(pixelDistance)}px`);
        this.renderer.setProperty(baselineSpan, 'textContent', `${Math.round(baselineDistance)}px@${resolutionSuffix}`);

        this.renderer.appendChild(label, pixelSpan);
        this.renderer.appendChild(label, baselineSpan);

        break;
      }
      default:
        break;
    }
  }

  /**
   * Removes all guide lines from the DOM and clears the guides array.
   *
   */
  private removeGuideLines(): void {
    this.guides.forEach(guide => {
      if (guide.parentNode) {
        guide.parentNode.removeChild(guide);
      }
    });
    this.guides = [];
  }
}
