<div #datepickerContainer class="relative inline-block w-full text-left">
  <div
    tabindex="0"
    class="peer flex space-x-3 w-full h-10 bg-neutral-900 pl-4 pt-4 pr-10
      rounded-t text-sm/[16px] border-0 border-b-1 caret-cyan
      focus:border-b-cyan-highlight focus:outline-none focus:ring-0"
    [ngClass]="{
      'border-b-error': formControl.invalid,
      'border-b-gray-800': formControl.valid
    }"
    (click)="toggleDatepicker()"
    (keyup)="null"
  >
    <nrc-icon
      class="block w-4 h-4 -translate-y-1"
      [name]="'calendar'"
    />

    <div>
      <span class="text-sm tracking-wide">
        {{ currentDate() | date:'yyyy-MM-dd' }}
      </span>

      <div
        class="absolute ml-11 mt-0.5 top-0 left-0 text-xxs text-[#adadad]"
        [ngClass]="{
          'text-error': formControl.invalid
        }"
      >
        {{ label }}
      </div>
    </div>
  </div>

  @if (isDatepickerOpen) {
    <div class="fixed z-50 max-w-full p-2 mt-2 rounded-md shadow-lg bg-neutral-900 ring-1 ring-black ring-opacity-5">
      <div class="py-1">
        <div class="flex items-center justify-between px-3 py-2 bg-neutral-900">
          <nrc-button
            class="relative flex w-6 h-6"
            [icon]="'arrow-back'"
            [buttonType]="buttonType.ICON"
            [role]="actionRole.TRANSPARENT_SECONDARY"
            (clickEvent)="previousMonth()"
          />

          <div>{{ currentMonth() | date: 'MMMM yyyy' }}</div>

          <nrc-button
            class="relative flex w-6 h-6 rotate-180"
            [icon]="'arrow-back'"
            [buttonType]="buttonType.ICON"
            [role]="actionRole.TRANSPARENT_SECONDARY"
            (clickEvent)="nextMonth()"
          />
        </div>

        <table class="w-full">
          <thead>
            <tr>
              @for (day of daysOfWeek; track day) {
                <th class="px-1 py-2 text-center">{{ day }}</th>
              }
            </tr>
          </thead>

          <tbody>
            @for (week of calendar; track week) {
              <tr>
                @for (day of week; track day) {
                  <td
                    tabindex="0"
                    class="px-2 py-2 text-center cursor-pointer"
                    [ngClass]="{
                      'text-gray-400': (day.month + 1 | toString) !== (currentMonth() | date:'M'),
                      'hover:bg-cyan rounded': (day.month + 1 | toString) === (currentMonth() | date:'M'),
                      'disabled cursor-not-allowed opacity-50 bg-neutral-900': !(day.date | isDateRange:minDate:maxDate)
                    }"
                    (click)="selectDate(day)"
                    (keyup)="null"
                  >
                    {{ day.date | date: 'd' }}
                  </td>
                }
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  }
</div>
