import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '@newroom-connect/library/helpers';

@Pipe({
  standalone: true,
  name: 'isDateRange'
})
export class IsDateRangePipe implements PipeTransform {
  /**
   * Check if the given value is in range of min/max dates.
   *
   * @param value The value to check.
   * @param minDate
   * @param maxDate
   *
   * @returns Boolean indicating if the value is in range of min/max dates.
   */
  public transform(value: Date | string, minDate: Date | string, maxDate: Date | string): boolean {
    return DateHelper.isDateInRange(value, minDate, maxDate);
  }
}
