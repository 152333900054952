import { ChangeDetectionStrategy, Component, Input, computed, signal } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  standalone: true,
  selector: 'nrc-youtube',
  templateUrl: './youtube.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YouTubeComponent {
  @Input({ required: true }) public set source(value: string) {
    this.sourceSignal.set(value);
  }
  @Input() public title = 'YouTube Video Player';

  public sourceSignal = signal<string>('');

  // Sanitize the source URL to be used in the iframe and handle the youtube embed URL.
  public sourceSanitized = computed<SafeResourceUrl>(() => {
    const source = this.sourceSignal();

    if (!source) {
      return '';
    }

    let url = source;

    // Match YouTube URLs and extract video ID
    const youtubeRegex = /(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&\s]+)/;
    const match = url.match(youtubeRegex);

    if (match && match[1]) {
      url = `https://www.youtube.com/embed/${match[1]}`;
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  });

  constructor(
    private readonly sanitizer: DomSanitizer
  ) {}
}
