import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output, signal, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { debounceTime, take, takeUntil } from 'rxjs';
import { ReactiveFormsModule, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { FormControlPipe, TranslatePipe } from '@newroom-connect/library/pipes';
import { IFileHydrated, MediaItem } from '@newroom-connect/library/interfaces';
import { Level, MediaType } from '@newroom-connect/library/enums';
import { FileService, ToastService } from '@newroom-connect/library/services';

import { InputFileComponent, InputFileComponentSize, InputTextComponent, InputCheckboxComponent, InputSelectComponent } from '../../inputs';
import { NoteComponent } from '../../note/note.component';
import { DestroyableComponent } from '../../abstract/destroyable/destroyable.component';

type MediaLibraryItemPropertiesForm = {
  label: FormControl<string>;
  thumbnailId: FormControl<string | null>;
  fileId?: FormControl<string | null>;
  fileTags?: FormControl<string[]>;
  isThumbnailAsDetailImage?: FormControl<boolean>;
}

@Component({
  selector: 'nrc-media-library-item-properties',
  templateUrl: './media-library-item-properties.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextComponent,
    InputFileComponent,
    InputCheckboxComponent,
    NoteComponent,
    InputSelectComponent,
    FormControlPipe,
    TranslatePipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaLibraryItemPropertiesComponent extends DestroyableComponent implements OnInit {
  private readonly fileService = inject(FileService);
  private readonly toastService = inject(ToastService);
  private readonly formBuilder = inject(FormBuilder);

  @Input({ required: true }) public apiBaseUrl!: string;
  @Input({ required: true }) public projectId!: string;
  @Input({ required: true }) public itemIndex!: number;

  @Input() public set itemValue(value: Partial<MediaItem> | null) {
    if (!value) {
      return;
    }

    // Update signals
    this.type.set('fileId' in value ? MediaType.FILE : MediaType.CARD);
    this.label.set(value.label ?? '');
    this.thumbnail.set(value.thumbnail ?? null);

    if ('fileId' in value) {
      this.fileId.set(value.fileId ?? null);
      this.file.set(value.file ?? null);
      this.fileTags.set(value.fileTags ?? []);
    }

    if ('isThumbnailAsDetailImage' in value) {
      this.isThumbnailAsDetailImage.set(value.isThumbnailAsDetailImage ?? false);
    }

    // Initialize form controls
    this.initializeForm(value);

    // Register subscription to file tags control changes
    this.registerPropertiesChangeEvent();
  }

  @Output() public valueChange = new EventEmitter<{
    index: number;
    value: Partial<MediaItem>;
  }>();

  public readonly inputFileComponentSize = InputFileComponentSize;
  public readonly level = Level;

  // Form group
  public propertiesForm?: FormGroup<MediaLibraryItemPropertiesForm>;

  // State signals
  public readonly label = signal<string>('');
  public readonly thumbnail = signal<IFileHydrated | null>(null);
  public readonly file = signal<IFileHydrated | null>(null);
  public readonly fileId = signal<string | null>(null);
  public readonly fileTags = signal<string[] | null>(null);
  public readonly type = signal<MediaType>(MediaType.FILE);
  public readonly isThumbnailAsDetailImage = signal<boolean>(false);

  public readonly projectFileTags = signal<string[]>([]);

  /**
   *
   */
  public ngOnInit(): void {
    this.loadProjectFileTags();
  }

  /**
   * Loads project file tags from.
   *
   */
  private loadProjectFileTags(): void {
    this.fileService.getProjectFileTags(this.projectId)
      .pipe(take(1))
      .subscribe({
        next: (tags) => {
          this.projectFileTags.set(tags);
        },
        error: () => {
          this.toastService.setMessage({
            message: 'Failed to load project file tags.',
            level: Level.ERROR
          });
        }
      });
  }

  /**
   * Handles file change event.
   * @param file
   */
  public handleFileChangeEvent(file: IFileHydrated): void {
    this.file.set(file);
    this.fileId.set(file.id);

    this.emitValueChange();
  }

  /**
   * Handles thumbnail change event.
   * @param file
   */
  public handleThumbnailChangeEvent(file: IFileHydrated): void {
    this.thumbnail.set(file);

    this.emitValueChange();
  }

  /**
   * Registers subscription to file tags control changes and updates file when changed.
   * Also registers subscription to label control changes.
   */
  private registerPropertiesChangeEvent(): void {
    if (!this.propertiesForm) {
      return;
    }

    // Subscribe to form control changes
    this.propertiesForm.valueChanges
      .pipe(
        debounceTime(200),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        const valueChanged = JSON.stringify(value) !== JSON.stringify({
          label: this.label(),
          thumbnailId: this.thumbnail()?.id,
          fileId: this.fileId(),
          fileTags: this.fileTags(),
          isThumbnailAsDetailImage: this.isThumbnailAsDetailImage()
        });

        if (!valueChanged) {
          return;
        }

        this.emitValueChange();
      });

    // Subscribe specifically to file tags changes
    const fileTagsControl = this.propertiesForm.get('fileTags');

    if (fileTagsControl) {
      fileTagsControl.valueChanges
        .pipe(
          debounceTime(200),
          takeUntil(this.destroy$)
        )
        .subscribe((tags) => {
          const fileId = this.propertiesForm?.value.fileId;

          if (!fileId || !tags) {
            return;
          }

          this.fileService.updateFile(this.projectId, fileId, { tags: [...tags] })
            .pipe(take(1))
            .subscribe(() => {
              this.emitValueChange();
              this.toastService.setMessage({
                message: 'Successfully updated file tags.',
                level: Level.SUCCESS
              });
            });
        });
    }
  }

  /**
   * Initialize form controls.
   * @param value
   */
  private initializeForm(value: Partial<MediaItem>): void {
    // Base controls that are always present
    const baseControls = {
      label: new FormControl(value.label ?? '', { nonNullable: true }),
      thumbnailId: new FormControl(value.thumbnailId ?? null)
    } as MediaLibraryItemPropertiesForm;

    // Add file-specific controls if fileId exists
    if ('fileId' in value) {
      baseControls.fileId = new FormControl(value.fileId ?? null);
      baseControls.fileTags = new FormControl(value.fileTags ?? [], { nonNullable: true });

      this.fileId.set(value.fileId ?? null);
    }

    // Add thumbnail detail image control if that property exists
    if ('isThumbnailAsDetailImage' in value) {
      baseControls.isThumbnailAsDetailImage = new FormControl(value.isThumbnailAsDetailImage ?? false, { nonNullable: true });
    }

    this.propertiesForm = this.formBuilder.group(baseControls);

    // Set base signal values
    this.label.set(value.label ?? '');
  }

  /**
   * Emits current state as value change.
   */
  private emitValueChange(): void {
    if (!this.propertiesForm) {
      return;
    }

    const baseValue = {
      type: this.type(),
      label: this.propertiesForm.controls.label?.value ?? '',
      thumbnail: this.thumbnail() ?? undefined,
      thumbnailId: this.propertiesForm.controls.thumbnailId?.value ?? undefined
    };

    const value: Partial<MediaItem> = this.type() === MediaType.FILE
      ? {
        ...baseValue,
        file: this.file() ?? undefined,
        fileId: this.propertiesForm.controls.fileId?.value ?? undefined,
        fileTags: this.propertiesForm.controls.fileTags?.value ?? []
      }
      : {
        ...baseValue,
        isThumbnailAsDetailImage: this.propertiesForm.controls.isThumbnailAsDetailImage?.value ?? false
      };

    this.valueChange.emit({
      index: this.itemIndex,
      value
    });
  }
}
