import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { FormControlPipe } from '@newroom-connect/library/pipes';

import { InputComponent } from '../input.component';

@Component({
  selector: 'nrc-input-range',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormControlPipe
  ],
  templateUrl: './input-range.component.html',
  styleUrls: ['./input-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRangeComponent),
      multi: true
    }
  ]
})
export class InputRangeComponent extends InputComponent<number> implements AfterViewInit, OnChanges {
  private static readonly CYAN_HIGHLIGHT_HEX_COLOR = '#00cfff';

  @ViewChild('rangeElement') public rangeElementRef!: ElementRef<HTMLInputElement>;

  @Input({ required: true }) public min!: number;
  @Input({ required: true }) public max!: number;
  @Input() public isLabeled = true;

  @Output() public valueChange = new EventEmitter<number>();

  /**
   *
   */
  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(rangeValue => {
      this.updateSliderBackground(rangeValue);
    });

    this.updateSliderBackground(Number(this.rangeElementRef.nativeElement.value));
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['max'] || changes['formControl']) {
      this.updateSliderBackground(this.formControl.value);
    }
  }

  /**
   *
   * @param event
   */
  public onSliderChange(event: any): void {
    this.formControl.setValue(event.target.value);
  }

  /**
   *
   * @param event
   */
  public onSliderInput(event: any): void {
    this.updateSliderBackground(event.target.value);
  }

  /**
   *
   * @param event
   */
  public onInputChange(event: any): void {
    let currentValue = Number(event.target.value);

    if (isNaN(currentValue)) {
      currentValue = this.min;
    }

    this.formControl.setValue(Math.max(this.min, Math.min(this.max, currentValue)));
  }

  /**
   *
   * @param value
   */
  private updateSliderBackground(value: number): void {
    if (!this.rangeElementRef) {
      return;
    }

    // Calculate progress percentage based on value position between min and max
    const progress = ((value - this.min) / (this.max - this.min)) * 100;

    this.valueChange.emit(value);

    this.rangeElementRef.nativeElement.style.background = `linear-gradient(to right, ${InputRangeComponent.CYAN_HIGHLIGHT_HEX_COLOR} ${progress}%, #ffffff0d ${progress}%)`;
  }
}
