import { ITimezone } from '@newroom-connect/library/interfaces';

export class DateHelper {
  private static readonly TIMEZONES: ITimezone[] = [
    {
      'value': 'Europe/Berlin',
      'label': 'Berlin, Germany',
      'offset': 1
    }
  ];

  /**
   * Get the current timestamp as UNIX timestamp in milliseconds.
   *
   * @returns Current timestamp as UNIX timestamp in milliseconds.
   */
  public static getCurrentTimestamp(): number {
    return new Date().getTime();
  }

  /**
   *
   * @returns
   */
  public static getTimezones(): ITimezone[] {
    return DateHelper.TIMEZONES;
  }

  /**
   *
   * @param value
   * @param minDate
   * @param maxDate
   *
   * @returns
   */
  public static isDateInRange(value: Date | string, minDate: Date | string, maxDate: Date | string): boolean {
    if (!value || !minDate || !maxDate) {
      return false;
    }

    const date = new Date(value);
    const min = new Date(minDate);
    const max = new Date(maxDate);

    return date >= min && date <= max;
  }
}
