@if (selectedLanguage()) {
  @switch (type) {
    @case (languageSelectorType.REGULAR) {
    <div class="relative flex flex-col items-center justify-center w-full h-full cursor-pointer grow">
      <div
        class="flex items-center justify-center space-x-1 max-h-1/2"
        [tabindex]="tabIndex"
        (click)="toggleOptions()"
        (keydown)="null"
      >
        <div
          class="relative"
          [ngClass]="{ 'animate-blink-scale duration-300': (invalidItems | isNonEmptyArray) && !showOptions() }"
          @fadeInOutAnimation
        >
          @if ((selectedLanguage() | hasSetValue:invalidItems) || ((invalidItems | isNonEmptyArray) && !showOptions())) {
            <div
              class="ml-1 absolute left-0 bottom-0 -translate-y-1 translate-x-2 transition-all w-2.5 h-2.5 flex justify-center items-center rounded-full bg-error"
              @fadeInOutAnimation>
              <nrc-icon
                class="left-0 w-1/2 h-1/2"
                [name]="'exclamation-mark'"
                [fillColor]="'black'"
              />
            </div>
          }

          <div class="relative z-10">
            <nrc-language
              class="duration-300"
              [code]="selectedLanguage() ?? ''"
              [iconClassList]="[
                textSize === languageSelectorTextSize.SMALL ? 'h-3 w-3' : 'h-4 w-4'
              ]"
              [textClassList]="[
                (selectedLanguage() | hasSetValue:invalidItems) || ((invalidItems | isNonEmptyArray) && !showOptions()) ? 'text-error' : 'text-white',
                textSize === languageSelectorTextSize.SMALL ? 'text-xxs' : 'text-sm'
              ]"
            />
          </div>
        </div>

        <nrc-button
          class="relative flex transition-transform duration-250"
          [ngClass]="{
            'w-6 h-6': textSize === languageSelectorTextSize.REGULAR,
            'w-4 h-4': textSize === languageSelectorTextSize.SMALL,
            'rotate-90': showOptions(),
            '-rotate-90': !showOptions()
          }"
          [icon]="'arrow-back'"
          [buttonType]="buttonType.ICON"
          [role]="actionRole.TRANSPARENT_SECONDARY"
          (clickEvent)="toggleOptions()"
        />
      </div>

      @if (showOptions()) {
        <div
          class="flex flex-col p-0.5 space-y-1 w-full min-w-24 max-h-20 bg-[#2d2d2d] overflow-auto rounded-b-md transition-transform duration-500 z-20 "
          [ngClass]="{
            'justify-center items-center': options.length === 0,
            'min-w-24': textSize === languageSelectorTextSize.REGULAR,
            'min-w-20': textSize === languageSelectorTextSize.SMALL,
          }"
          [@growAnimation]="{
            value: '',
            params: {
              heightStart: '0px',
              heightEnd: ((options.length - 1) * 24) + 'px'
            }
          }"
        >

          @for (option of options; let i = $index; track i;) {
            @if (option !== selectedLanguage()) {
              <div
                class="relative"
                [ngClass]="{ 'animate-blink-scale duration-300': (option | hasSetValue:invalidItems) }"
                @fadeInOutAnimation
              >
                @if ((option | hasSetValue:invalidItems)) {
                  <div
                    class="ml-1 absolute left-0 bottom-0 -translate-y-1 translate-x-2 transition-all w-2.5 h-2.5 flex justify-center items-center rounded-full bg-error"
                    @fadeInOutAnimation
                  >
                    <nrc-icon
                      class="left-0 w-1/2 h-1/2"
                      [name]="'exclamation-mark'"
                      [fillColor]="'black'"
                    />
                  </div>
                }

                <div class="relative transition-transform transform hover:scale-200 duration-250">
                  <nrc-language
                    [tabindex]="tabIndex"
                    [code]="option"
                    [iconClassList]="[
                      textSize === languageSelectorTextSize.SMALL ? 'h-3 w-3' : 'h-4 w-4'
                    ]"
                    [textClassList]="[
                      (option | hasSetValue:invalidItems) ?  'text-error' : 'text-white',
                      textSize === languageSelectorTextSize.SMALL? 'text-xxs' : 'text-sm'
                    ]"
                    (click)="selectLanguage(option, true)"
                    (keydown)="null"
                  />
                </div>
              </div>
            }
          }
        </div>
      }
    </div>
    }

    @case (languageSelectorType.LABELED) {
      <div class="relative flex flex-col w-full h-full space-y-1">
        <!-- The input element itself -->
        <div
          tabindex="0"
          aria-hidden="true"
          #selectInputElement
          class="h-full flex flex-col justify-center items-stretch cursor-pointer rounded-t-[5px] bg-[#262626] p-1 pl-2 pr-2 border-0 border-b-gray-700"
          [ngClass]="borderClass"
          (click)="this.toggleOptions()"
        >
          @if (label) {
            <span
              class="ml-7 text-xxs"
              [ngClass]="{
                'text-error' : formControl.touched && formControl.invalid,
                'text-cyan': showOptions() && !(formControl.touched && formControl.invalid),
                'text-gray-400': !showOptions() && !(formControl.touched && formControl.invalid)
              }"
            >{{ label }}</span>
          }

          <div class="flex items-center justify-between">
            <nrc-language
              [tabindex]="tabIndex"
              [code]="selectedLanguage()!"
              [iconClassList]="[textSize === languageSelectorTextSize.SMALL ? 'h-4 w-4' : 'h-5 w-5']"
              [textClassList]="[textSize === languageSelectorTextSize.SMALL? 'text-xxs' : 'text-sm']"
            />

            <nrc-button
              class="relative flex transition-transform duration-250"
              [ngClass]="{
                'w-6 h-6': textSize === languageSelectorTextSize.REGULAR,
                'w-4 h-4': textSize === languageSelectorTextSize.SMALL,
                'rotate-90': showOptions(),
                '-rotate-90': !showOptions()
              }"
              [icon]="'arrow-back'"
              [buttonType]="buttonType.ICON"
              [role]="actionRole.TRANSPARENT_SECONDARY"
              (clickEvent)="toggleOptions()"
            />
          </div>
        </div>

        @if (showOptions()) {
          <div
            [nrcOverflowTarget]="selectInputElement"
            [parentElement]="overflowElement"
            [elementHeight]="70"
            [elementSpaceY]="-3"
            class="w-full max-h-84 bg-[#525252] overflow-auto rounded-b-md transition-transform duration-500 z-20 "
            [ngClass]="{
              'flex justify-center items-center': options.length === 0
            }"
            [@growAnimation]="{
              value: '',
              params: {
                heightStart: '0px',
                heightEnd: ((options.length - 1) * 24) + 'px'
              }
            }"
          >
            @if (options.length === 0) {
              <nrc-loading class="relative my-8"/>
            } @else if (options | isNonEmptyArray) {
                @for (option of options; track i; let i = $index) {
                  <div
                    class="group relative flex items-center text-xs/[14px] text-[#f8f8f8] transition-[color] duration-500 ease-in-out p-1 cursor-pointer hover:bg-white/5"
                    tabindex="0"
                    aria-hidden="true"
                    (click)="selectLanguage(option, true)"
                  >
                    <nrc-language
                      [tabindex]="tabIndex"
                      [code]="option"
                      [iconClassList]="[
                        textSize === languageSelectorTextSize.SMALL ? 'h-3 w-3' : 'h-4 w-4'
                      ]"
                      [textClassList]="[textSize === languageSelectorTextSize.SMALL? 'text-xs' : 'text-md']"
                    />
                  </div>
                }
            }
          </div>
        }
      </div>
    }
    @default {
      <p>Provided style not supported.</p>
    }
  }

}