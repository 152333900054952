<div class="relative flex flex-col w-full">
  <label
    [for]="id"
    class="text-xs text-[#adadad] ml-2 pb-2"
    [ngClass]="{
      'text-error peer-focus:text-error': formControl.touched && formControl.invalid
    }"
  >{{ label }}</label>

  <div class="relative flex items-center w-full">
    <button
      type="button"
      [disabled]="readonly || formControl.disabled || (min !== undefined && formControl.value <= min)"
      class="bg-white/5 hover:cursor-pointer rounded-s-lg p-1.5 h-7 w-7 flex items-center
        focus:ring-2 focus:outline-none
        disabled:opacity-50 disabled:hover:bg-white/10"
      (click)="decrement()"
    >

      <nrc-icon [name]="'minus'" class="flex w-2.5 h-2.5" [fillColor]="'semi-transparent'" />
    </button>

    <input
      #inputElement
      type="number"
      [name]="name"
      [id]="id"
      [min]="min ?? null"
      [max]="max ?? null"
      [step]="step"
      [tabindex]="tabIndex"
      [autocomplete]="name"
      [readOnly]="readonly"
      [placeholder]="placeholder"
      [formControl]="formControl | formControl"
      (input)="handleInputChange($event)"
      class="bg-white/5 h-7 text-center text-white text-xs w-full px-1
        focus:ring-transparent focus:border-cyan-600 border-transparent focus:outline-none
        disabled:opacity-50"
      [ngClass]="{
        'border-error focus:border-error': formControl.touched && formControl.invalid,
        classList
      }"
    />

    <button
      type="button"
      [disabled]="readonly || formControl.disabled || (max !== undefined && formControl.value >= max)"
      class="bg-white/5 hover:cursor-pointer rounded-e-lg p-1.5 h-7 w-7 flex items-center
        focus:ring-2 focus:outline-none
        disabled:opacity-50 disabled:hover:bg-white/10"
      (click)="increment()"
    >
      <nrc-icon [name]="'plus'" class="flex w-2 h-2" [fillColor]="'semi-transparent'" />
    </button>
  </div>

  @if (hint) {
    <p class="mt-2 text-sm text-white/50">
      {{ hint }}
    </p>
  }

  @if (invalidHint && formControl.invalid && formControl.touched) {
    <p class="mt-2 text-sm text-error">
      {{ invalidHint }}
    </p>
  }
</div>