@if (translatableFormControls()) {
  <div class="relative flex flex-col w-full rounded-md">
    <!-- Header section with language selector and editor button -->
    <div class="w-full px-2 bg-white/10 rounded-t-lg shrink-0">
      <div class="flex flex-row justify-between items-center">
        <div class="relative w-1/2 h-8 pt-2">
          <nrc-input-language-selector
            class="absolute z-20"
            [formControl]="languageCodeFormControl"
            [options]="languageCodes"
            [value]="currentLanguageCode()"
            [textSize]="inputLanguageSelectorTextSize.SMALL"
            (languageChanged)="handleLanguageChangeEvent($event)"
          />
        </div>

        <div class="w-8 h-8 flex items-center justify-center">
          <nrc-button
            class="w-7 h-7 relative flex"
            [icon]="'editor'"
            [buttonType]="buttonType.ICON"
            [role]="actionRole.TRANSPARENT_SECONDARY"
            [tooltipText]="'Expand Advanced mode'"
            (clickEvent)="toggleWYSIWYGModal()"
          />
        </div>
      </div>
    </div>

    <!-- Content section with scrollable container -->
    <div class="flex flex-row w-full min-h-0 flex-grow">
      <div class="flex flex-col relative w-full">
        <div class="h-full rounded-lg">
          <nrc-wysiwyg-editor
            [hideToolbar]="true"
            [value]="currentValuePerLanguageCode()"
            [minimumHeight]="'9rem'"
            [maximumHeight]="'9rem'"
            [formControl]="translatableFormControls()[currentLanguageCode()] | formControl"
          />
        </div>
      </div>
    </div>
  </div>
}

<ng-template #wysiwygModalContent>
  @if (languageCodes | isNonEmptyArray) {
    <div class="absolute top-4 left-1/2 -translate-x-1/2 -ml-4 z-30">
      <div class="flex justify-center items-end">
        <div class="flex space-x-1">
          <nrc-input-language-selector
            class=""
            [options]="languageCodes"
            [formControl]="languageCodeFormControl"
            [value]="currentLanguageCode()"
            (languageChanged)="handleLanguageChangeEvent($event)"
          />

          <nrc-button
            class="w-8 h-8 relative flex transition-transform duration-250 justify-end"
            [icon]="'translate'"
            [buttonType]="buttonType.ICON"
            [role]="actionRole.TRANSPARENT_SECONDARY"
            (clickEvent)="toggleAutoTranslateModal()"
          />
        </div>
      </div>
    </div>
  }

  <nrc-wysiwyg-editor
    class="relative z-20"
    [formControl]="translatableFormControls()[currentLanguageCode()] | formControl"
    (valueChanged)="handleValueChangedEvent($event)"
  />
</ng-template>


<ng-template #autoTranslateModalContent>
  <nrc-auto-translate
    class="flex flex-col grow justify-around"
    [languageCodes]="languageCodes"
    [selectedLanguageCode]="currentLanguageCode()"
    [inputsToTranslate]="autoTranslateInputs()"
    [translationModalParams]="translateModalParams"
    (autoTranslateResult)="handleAutoTranslateResult($event)"
    (closeEvent)="toggleAutoTranslateModal()"
  />
</ng-template>