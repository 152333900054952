import { Injectable } from '@angular/core';
import { TrackingEventType } from '@newroom-connect/library/enums';

import { EntityService } from '../entity/entity.service';

export interface ICreateTrackingEventInput {
  eventType: TrackingEventType;
  sessionId: string;
  languageCode: string;
  duration?: number;
  deviceInfo?: string;
  country?: string;
  region?: string;
  city?: string;
  metadata?: string;
  areaId?: string;
  areaElementId?: string;
  fileId?: string;
  actionId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TrackingEventService extends EntityService {
  public searchableProperties = [];

  /**
   *
   * @param projectId
   * @param input
   */
  public createTrackingEvent(projectId: string, input: ICreateTrackingEventInput): void {
    this.apiService.post(`projects/${projectId}/tracking-events`, input);
  }
}
