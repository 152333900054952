import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { takeUntil } from 'rxjs';
import { ActionRole, ButtonType, Level, ModalSize, ObjectPropertyFormat } from '@newroom-connect/library/enums';
import { ArrayHelper } from '@newroom-connect/library/helpers';
import { IAPIListResponseMeta, IFileHydrated, IFileUploadItem, IListConfigurationColumn, IListItem, IListItemClickedEvent, IListOptions, ListItemType } from '@newroom-connect/library/interfaces';
import { FileListService } from '@newroom-connect/library/services';
import { TranslatePipe } from '@newroom-connect/library/pipes';

import { ModalComponent, ModalService } from '../../modal/modal.component';
import { DestroyableComponent } from '../../abstract/destroyable/destroyable.component';

import { FileLibraryModalSearchComponent } from './file-library-modal-search/file-library-modal-search.component';
import { FileLibraryModalSelectorComponent } from './file-library-modal-selector/file-library-modal-selector.component';
import { FileLibraryModalFooterComponent } from './file-library-modal-footer/file-library-modal-footer.component';

@Component({
  selector: 'nrc-file-library-modal',
  templateUrl: './file-library-modal.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalComponent,
    FileLibraryModalSearchComponent,
    FileLibraryModalSelectorComponent,
    FileLibraryModalFooterComponent,
    TranslatePipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileLibraryModalComponent extends DestroyableComponent implements AfterViewInit, OnChanges {
  @Input() public currentFile?: IFileHydrated;
  @Input() public fileListItemsSig = signal<IListItem<IFileHydrated>[]>([]);
  @Input() public acceptableMimeTypes: string[] = [];
  @Input() public metaSig = signal<IAPIListResponseMeta | undefined>(undefined);
  @Input() public hideSelection = false;
  @Input() public isFileListLoading?: boolean;

  @Output() public showFileLibraryEvent = new EventEmitter<void>();
  @Output() public fileUploadEvent = new EventEmitter<IFileUploadItem[]>();
  @Output() public initEvent = new EventEmitter<void>();
  @Output() public fileListEvent = new EventEmitter<IListOptions>();
  @Output() public fileSearchEvent = new EventEmitter<string>();
  @Output() public closeEvent = new EventEmitter<IFileHydrated>();

  @ViewChild('fileLibraryContent') public fileLibraryContent!: TemplateRef<any>;

  public showFileLibrarySig = signal<boolean>(false);

  public columnsSig = signal<IListConfigurationColumn[]>(FileListService.generateGridLayoutListConfiguration());

  public fileTitleForm = new FormGroup({
    title: new FormControl({ value: '', disabled: true })
  });

  public buttonType = ButtonType;
  public actionRole = ActionRole;
  public level = Level;
  public modalSize = ModalSize;
  public objectPropertyFormat = ObjectPropertyFormat;
  public listItemType = ListItemType;

  constructor(private modalService: ModalService) {
    super();
  }

  /**
   *
   */
  public ngAfterViewInit(): void {
    this.initEvent.emit();

    const modalRef = this.modalService.open({
      component: ModalComponent,
      inputs: {
        id: 'FileLibraryModal',
        size: ModalSize.MEDIUM,
        title: 'File Library',
        paddedContent: true
      },
      content: this.fileLibraryContent
    });

    modalRef.instance.closeEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.modalService.close('FileLibraryModal');
    });
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['hideSelection']?.currentValue) {
      this.showFileLibrarySig.set(true);
    }
  }

  /**
   *
   */
  public toggleShowFileLibrary(): void {
    this.showFileLibrarySig.update(showFileLibrary => !showFileLibrary);

    if (this.showFileLibrarySig()) {
      this.showFileLibraryEvent.emit();
    }
  }

  /**
   *
   * @param fileSelectedEvent
   */
  public handleFileSelectedEvent(fileSelectedEvent: IListItemClickedEvent<IListItem<IFileHydrated>>): void {
    this.currentFile = fileSelectedEvent.item?.value;

    const fileTranslations = fileSelectedEvent.item?.value.translations;
    const fileTitle = fileTranslations && ArrayHelper.isNotEmpty(fileTranslations) ? fileTranslations[0].title : '';

    this.fileTitleForm.controls['title'].patchValue(fileTitle);
  }

  /**
   *
   * @param result
   */
  public closeModal(result?: IFileHydrated): void {
    this.closeEvent.emit(result);

    this.modalService.close('FileLibraryModal', result);
  }
}
