@switch (type) {
  @case (inputRadioComponentType.REGULAR) {
    <div class="w-full flex justify-start space-x-4">
      @for (option of options; let i = $index; track i) {
        <div class="flex items-center min-h-10 pl-4 pr-4 bg-neutral-900 rounded">
          <input
            [id]="option.id ?? (id + '-option-' + i)"
            [name]="name"
            type="radio"
            [value]="option.value"
            [tabindex]="tabIndex"
            class="peer w-4 h-4 border-2 border-solid border-gray-50 text-neutral-900 shadow-[0_0_0_1px_#ffffff] appearance-none rounded-full bg-gray-50 ease-in duration-200 focus:ring-0 checked:w-5 checked:h-5 checked:bg-neutral-900"
            [ngModel]="selectedOption()"
            (click)="selectOption(option.value)"
          />

          <label
            [for]="option.id ?? (id + '-option-' + i)"
            class="py-4 ml-2 text-sm font-medium text-gray-500 peer-checked:text-gray-50"
          >{{ option.label | titlecase }}</label>
        </div>
      }
    </div>
  }
  @case (inputRadioComponentType.CARDS) {
    <ul class="w-full grid grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-2">
      @for (option of options; let i = $index; track i) {
        <li
          class="relative"
          [ngClass]="{
            'h-[4.5rem]': !size || size === inputRadioComponentSize.MEDIUM,
            'h-40': size === inputRadioComponentSize.LARGE,
          }"
          [tabindex]="i"
          (click)="selectOption(option.value)"
          (keyup)="null"
        >
          <input
            type="radio"
            class="hidden peer"
            [id]="option.id ?? (id + '-option-' + i)"
            [name]="name"
            [value]="option.value"
            [tabindex]="tabIndex"
            [ngModel]="selectedOption()"
          />

          <label
            [for]="id + '-option-' + i"
            class="w-full h-full px-2 py-1 inline-flex justify-center items-center text-white/50 border-0 cursor-pointer rounded-10
            peer-checked:border-cyan-highlight peer-checked:text-white hover:text-white/75"
            [ngClass]="{
              'flex-row space-x-2 bg-white/10 hover:bg-white/20 peer-checked:border-1': !size || size === inputRadioComponentSize.MEDIUM,
              'flex-col space-y-6 bg-transparent border-1 border-white/10 hover:bg-white/10 peer-checked:bg-white/10 peer-checked:border-2': size === inputRadioComponentSize.LARGE
            }"
          >
            @if (option.icon) {
              <nrc-icon
                class="flex justify-center items-center shrink-0 rounded-full"
                [ngClass]="{
                  'w-8 h-8 p-2 bg-white/5': !size || size === inputRadioComponentSize.MEDIUM,
                  'w-10 h-10': size === inputRadioComponentSize.LARGE
                }"
                [name]="option.icon | toString"
                [fillColor]="option.value === selectedOption() ? 'cyan' : 'semi-transparent'"
              />
            }

            <span class="font-light text-sm text-center tracking-wide">
              {{ option.label | titlecase }}
            </span>
          </label>

          @if (option.description) {
            <div class="group w-5 h-5 absolute right-2 top-2">
              <nrc-icon
                class="absolute peer w-5 h-5"
                [name]="'info-outline'"
                [fillColor]="'cyan'"
              />

              <nrc-tooltip
                class="tooltip tooltip-group pt-2"
                [text]="option.description"
              />
            </div>
          }
        </li>
      }
    </ul>
  }
  @case (inputRadioComponentType.ROWS) {
    <div class="flex flex-col space-y-1 items-center">
      @for (option of options; let i = $index; track i) {
        <div
          class="p-2 cursor-pointer peer bg-white/5 border-0 shadow-md rounded-10 w-full hover:bg-white/10"
          [ngClass]="{
            'border-1 border-cyan-highlight bg-white/20': option.value === selectedOption(),
          }"
        >
          <div class="flex items-center space-x-2">
            <input
              [id]="option.id ?? (id + '-option-' + i)"
              [name]="name"
              type="radio"
              [value]="option.value"
              [tabindex]="tabIndex"
              class="hidden"
              [ngModel]="selectedOption()"
              (click)="selectOption(option.value)"
            />

            @if (option.icon) {
              <div class="bg-white/15 p-2 rounded-md flex items-center justify-center">
                <nrc-icon
                  class="flex justify-center items-center shrink-0 rounded-full"
                  [ngClass]="{
                    'w-5 h-5 bg-white/5': !size || size === inputRadioComponentSize.MEDIUM,
                    'w-8 h-8': size === inputRadioComponentSize.LARGE
                  }"
                  [name]="option.icon | toString"
                  [fillColor]="option.value === selectedOption() ? 'white' : 'semi-transparent'"
                />
              </div>
            }

            <label
              [for]="option.id ?? (id + '-option-' + i)"
              class="w-full h-full px-2 py-1 flex flex-col justify-start items-start text-white/50 cursor-pointer text-ellipsis text-nowrap overflow-hidden"
            >
              <span
                class="font-light text-sm text-center tracking-wide"
                [ngClass]="{
                  'text-white': option.value === selectedOption()
                }"
              >
                {{ option.label }}
              </span>

              @if (option.description) {
                <span
                  class="font-light text-xs"
                  [ngClass]="{
                    'text-white': option.value === selectedOption()
                  }"
                >
                  {{ option.description }}
                </span>
              }
            </label>
          </div>
        </div>
      }
    </div>
  }
  @case (inputRadioComponentType.COLUMNS) {
    <div class="flex flex-nowrap w-full gap-3">
      @for (option of options; let i = $index; track i) {
        <div
          class="group relative flex items-center cursor-pointer bg-white/5 shadow-md rounded-md hover:bg-white/10"
          [tabindex]="i"
          (click)="selectOption(option.value)"
          (keyup)="null"
        >
          <input
            type="radio"
            class="hidden"
            [id]="option.id ?? (id + '-option-' + i)"
            [name]="name"
            [value]="option.value"
            [tabindex]="tabIndex"
            [ngModel]="selectedOption()"
            (click)="selectOption(option.value)"
          />

          @if (option.icon) {
            <div class="bg-white/5 p-2 flex items-center justify-center rounded-md group-hover:bg-white/10">
              <nrc-icon
                class="flex justify-center items-center shrink-0"
                [ngClass]="{
                  'w-5 h-5': !size || size === inputRadioComponentSize.MEDIUM,
                  'w-9 h-9': size === inputRadioComponentSize.LARGE
                }"
                [name]="option.icon | toString"
                [fillColor]="option.value === selectedOption() ? 'white' : 'semi-transparent'"
              />
            </div>
          }

          <div
            class="flex items-center transition-[max-width] duration-500 overflow-hidden"
            [ngClass]="{
              'max-w-0': option.value !== selectedOption(),
              'max-w-28': option.value === selectedOption()
            }"
          >
            <label
              [for]="option.id ?? (id + '-option-' + i)"
              class="w-full p-2 cursor-pointer"
            >
              <span
                class="font-light text-sm text-center tracking-wide whitespace-nowrap text-nowrap text-ellipsis overflow-hidden transition-opacity duration-300"
                [ngClass]="{
                  'opacity-0': option.value !== selectedOption(),
                  'opacity-1': option.value === selectedOption()
                }"
              >
                {{ option.label ?? 'Unnamed' }}
              </span>
            </label>
          </div>

          @if (option.value !== selectedOption()) {
            <nrc-tooltip
              class="tooltip tooltip-group"
              [text]="option.label ?? 'Unknown'"
            />
          }
        </div>
      }
    </div>

    <!-- Only display the description of the selected option underneath all available options -->
    @if (selectedOption() && selectedOptionDescription()) {
      <span class="flex text-xs text-white/50 font-light pt-2 text-pretty">
        {{ selectedOptionDescription() }}
      </span>
    }
  }
}
