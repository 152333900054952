<div class="flex flex-col justify-center items-center space-y-3 p-4">
  <div class="w-24 h-24 bg-white/5 flex justify-center items-center rounded-full p-2">
    <nrc-icon
      class="flex w-16 h-16"
      [name]="this.canCreateCards ? 'card' : 'file-drop'"
      [fillColor]="'semi-transparent'"
    />
  </div>

  <p class="font-light text-xl text-neutral-100">
    {{ (this.canCreateCards ? 'COMPONENTS.MEDIA_LIBRARY.EMPTY.HEADLINE.CARD' : 'COMPONENTS.MEDIA_LIBRARY.EMPTY.HEADLINE.FILE') | translate }}
  </p>

  <p class="font-light tracking-wide text-xs text-[#f8f8f8b2] max-w-sm text-center">
    {{ (this.canCreateCards ? 'COMPONENTS.MEDIA_LIBRARY.EMPTY.CONTENT.CARD' : 'COMPONENTS.MEDIA_LIBRARY.EMPTY.CONTENT.FILE') | translate }}
  </p>

  <nrc-button
    [label]="(this.canCreateCards ? 'COMPONENTS.MEDIA_LIBRARY.EMPTY.BUTTON.CARD' :'COMPONENTS.MEDIA_LIBRARY.EMPTY.BUTTON.FILE' )| translate"
    (clickEvent)="actionButtonClicked.emit()"
  />
</div>
