import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';

import { IconComponent } from '../../icon/icon.component';
import { ButtonComponent } from '../../buttons/button/button.component';
import { ActionButton } from '../../buttons/action-button-menu-item/action-button-menu-item.component';

export interface NavigationItem {
  label: string;
  index: number;
}

@Component({
  selector: 'nrc-media-library-header',
  templateUrl: './media-library-header.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconComponent,
    ButtonComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaLibraryHeaderComponent {
  @Input() public label?: string;
  @Input() public canNavigateBack = false;
  @Input() public actionButtons: ActionButton[] = [];
  @Input() public navigationHistory: NavigationItem[] = [];

  @Output() public backButtonClickEvent = new EventEmitter<void>();
  @Output() public actionButtonClickEvent = new EventEmitter<ActionButton>();
  @Output() public navigateToLevelEvent = new EventEmitter<number>();

  public buttonType = ButtonType;
  public actionRole = ActionRole;

  /**
   *
   * @param index
   */
  public navigateToLevel(index: number): void {
    this.navigateToLevelEvent.emit(index);
  }
}
