@if (backgroundLoadingStatusSig() === 'failed') {
  <div class="w-full h-full flex justify-center items-center">
    <p>{{ 'COMPONENTS.SHARED.CUBEMAP.LOADING_BACKGROUND_FAILED' | translate }}</p>
  </div>
} @else {
  <div #cubemapCanvasContainer class="relative w-full h-full">
    <canvas #cubemapCanvas class="absolute w-full h-full top-0 left-0 hover:cursor-pointer"></canvas>
    <div #css3dContainer class="absolute w-full h-full top-0 left-0 pointer-events-none"></div>

    @if (backgroundLoadingStatusSig() === 'loading') {
      <div class="absolute bg-black/75 z-10 w-full h-full top-0 left-0 flex justify-center items-center">
        <p>{{ backgroundLoadingProgressSig() }}&percnt;</p>
      </div>
    }
  </div>
}
