import { Injectable, OnDestroy, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { ICrossAppMessage, ICrossAppMessageHandler, SupportedCrossAppEventType, ICrossAppMessageData } from '@newroom-connect/library/interfaces';

import { LoggingService } from '../logging/logging.service';

@Injectable({
  providedIn: 'root'
})
export class CrossAppMessageService implements OnDestroy {
  private static readonly CROSS_APP_MESSAGE = 'message';

  private messageSubject = new Subject<ICrossAppMessage>();
  private handlers: ICrossAppMessageHandler[] = [];
  private isConnected = signal<boolean>(false);

  /**
   *
   * @param loggingService
   * @returns
   */
  constructor(private readonly loggingService: LoggingService) {
    if (this.isConnected()) {
      return;
    }

    this.initializeMessageListener();
    this.isConnected.set(true);
  }

  /**
   * Clean up resources when the service is destroyed.
   */
  public ngOnDestroy(): void {
    window.removeEventListener(CrossAppMessageService.CROSS_APP_MESSAGE, this.handleMessage);
    this.messageSubject.complete();
  }

  /**
   * Register a handler for specific message types.
   *
   * @param type The type of message to handle.
   * @param callback The callback function to execute when a message of the specified type is received.
   */
  public registerHandler(type: string, callback: (message: ICrossAppMessage) => void): void {
    this.handlers.push({ type, callback });
  }

  /**
   * Remove a specific message handler.
   *
   * @param type The type of message the handler was registered for.
   * @param callback The callback function to remove.
   */
  public removeHandler(type: string, callback: (message: ICrossAppMessage) => void): void {
    this.handlers = this.handlers.filter(
      handler => !(handler.type === type && handler.callback === callback)
    );
  }

  /**
   * Send a message cross-apps.
   *
   * @param type
   * @param data The partial data to be sent.
   */
  public sendMessage(type: SupportedCrossAppEventType, data: ICrossAppMessageData): void {
    // Example of crossAppMessage to be sent to other applications running within the current app.
    const fullMessage: ICrossAppMessage = {
      type,
      data,
      source: CrossAppMessageService.CROSS_APP_MESSAGE,
      timestamp: Date.now()
    };

    window.postMessage(fullMessage, '*');
  }

  /**
   * Initialize the nrc-app event listener for cross-app communication.
   */
  private initializeMessageListener(): void {
    window.addEventListener(CrossAppMessageService.CROSS_APP_MESSAGE, this.handleMessage);
  }

  /**
   * Handle incoming messages and execute registered handlers.
   *
   * @param event The message event containing the cross-app message.
   */
  private handleMessage(event: MessageEvent): void {
    if (Object.keys(SupportedCrossAppEventType).includes(event.data.type)) {
      try {
        const message: ICrossAppMessage = {
          type: event.data.type,
          data: event.data.data,
          timestamp: Date.now()
        };

        this.messageSubject.next(message);

        // Execute registered handlers
        this.handlers
          .filter(handler => handler.type === message.type)
          .forEach(handler => handler.callback(message));
      } catch (error) {
        this.loggingService.error('Error processing message:', error);
      }
    }
  }
}
