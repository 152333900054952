import { trigger, transition, style, animate, state } from '@angular/animations';

export const collapseAnimation = trigger('collapseAnimation', [
  state('open', style({
    height: '{{ height }}',
    width: '{{ width }}'
  }), { params: { height: '*', width: '*' } }),
  state('closed', style({
    height: '{{ height }}',
    width: '{{ width }}',
    overflow: 'hidden'
  }), { params: { height: '*', width: '*' } }),
  transition('open <=> closed', [
    animate('0.25s ease-in-out')
  ])
]);
