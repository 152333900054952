import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, forwardRef, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { takeUntil, catchError, tap, take } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { IAPIListResponseMeta, IFileHydrated, IFileUploadItem, IListItem, IListOptions } from '@newroom-connect/library/interfaces';
import { ExtractPropertiesPipe, JoinPipe, TranslatePipe } from '@newroom-connect/library/pipes';
import { ButtonType, ActionRole, ObjectPropertyFormat } from '@newroom-connect/library/enums';
import { ListService, FileBroadcastEvent, FileService, FileUploadService, FileErrorHandlerService, MediaService, FileListService } from '@newroom-connect/library/services';
import { fadeInOutAnimation } from '@newroom-connect/library/animations';

import { InputComponent } from '../input.component';
import { InputTextComponent } from '../input-text/input-text.component';
import { IconComponent } from '../../icon/icon.component';
import { MediaPreviewComponent } from '../../media-preview/media-preview.component';
import { ButtonComponent } from '../../buttons/button/button.component';
import { FileLibraryModalComponent } from '../../files/file-library-modal/file-library-modal.component';

export enum InputFileComponentSize {
  REGULAR = 'REGULAR',
  SMALL = 'SMALL'
}

@Component({
  selector: 'nrc-input-file',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconComponent,
    InputTextComponent,
    MediaPreviewComponent,
    FileLibraryModalComponent,
    ButtonComponent,
    TranslatePipe,
    ExtractPropertiesPipe,
    JoinPipe
  ],
  templateUrl: './input-file.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true
    }
  ],
  animations: [fadeInOutAnimation]
})
export class InputFileComponent extends InputComponent<string> implements OnChanges, OnInit, OnDestroy {
  @Input({ required: true }) public projectId!: string;
  @Input({ required: true }) public apiBaseUrl!: string;
  @Input() public size: InputFileComponentSize = InputFileComponentSize.REGULAR;
  @Input() public searchTerm?: string;
  @Input() public mimetype?: string;
  @Input() public fileLibraryModalOnly = false;
  @Input() public hideSelection = false;
  @Input() public fileTitle?: string;
  @Input() public mediaType?: string[];
  @Input() public uploadButtonLabel?: string;

  @Output() public fileChangedEvent = new EventEmitter<IFileHydrated>();
  @Output() public fileLibraryModalEvent = new EventEmitter<boolean>();

  public animateFilePreview = signal<boolean>(false);
  public backgroundFileUrlFormControl = new FormControl<string>('');
  public backgroundFileTitleFormControl = new FormControl<string>('');

  public fileListInputSig = signal<IListItem<IFileHydrated>[]>([]);
  public fileListMetaSig = signal<IAPIListResponseMeta | undefined>(undefined);
  public currentFile = signal<IFileHydrated | undefined>(undefined);
  public acceptableMimeTypes = signal<string[]>([]);

  public isFileListLoading = false;

  public isFileLibraryModalVisibleSig = signal<{ isVisible: boolean; hideSelection: boolean }>({
    isVisible: false,
    hideSelection: false
  });

  public buttonType = ButtonType;
  public actionRole = ActionRole;
  public inputFileComponentSize = InputFileComponentSize;
  public objectPropertyFormat = ObjectPropertyFormat;

  private readonly listService = inject(ListService);
  private readonly mediaService = inject(MediaService);
  private readonly fileErrorHandlerService = inject(FileErrorHandlerService);
  private readonly fileUploadService = inject(FileUploadService);
  private readonly fileListService = inject(FileListService);

  /**
   *
   */
  public override ngOnInit(): void {
    this.buildAcceptableMimeTypes(this.mediaType ?? []);
    this.initializeFormControls();
    this.toggleFileLibraryModalVisibility({ isVisible: this.fileLibraryModalOnly, hideSelection: this.hideSelection });
  }

  /**
   *
   */
  public override ngOnDestroy(): void {
    this.fileListInputSig.set([]);
    this.fileListMetaSig.set(undefined);
    this.currentFile.set(undefined);

    super.ngOnDestroy();
  }

  /**
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['formControl'] && !changes['formControl'].isFirstChange()) {
      const currentValue = changes['formControl'].currentValue.value;
      const previousValue = changes['formControl'].previousValue.value;

      if (JSON.stringify(currentValue) !== JSON.stringify(previousValue)) {
        this.initializeFormControls();
      }
    }
  }

  /**
   *
   * @param event
   */
  public onInputChange(event: IFileHydrated): void {
    this.formControl.setValue(event.id);
  }

  /**
   * Handle a search event from the file library modal where a new file list request is made based on the given search term.
   *
   * @param searchTerm The search term to use for the file list request.
   */
  public handleFileSearchEvent(searchTerm: string): void {
    this.handleFileListOptions(this.listService.generateListOptionsForSearch(searchTerm));
  }

  /**
   * Handle a pagination event from the file library modal.
   *
   * @param event An optional pagination event to handle. Otherwise, the last list options are used.
   */
  public handleFileListOptions(event?: IListOptions): void {
    if (event) {
      this.listService.lastListOptions$.next(event);
    }

    this.isFileListLoading = true;

    this.mediaService.listSources(this.projectId, this.listService.generateListOptionsForSearch(this.searchTerm ?? ''), this.mediaType).pipe(
      takeUntil(this.destroy$),
      catchError(() => this.fileErrorHandlerService.handleListError())
    ).subscribe(fileListResponse => {
      this.fileListInputSig.set(fileListResponse.data.map((file: IFileHydrated) => {
        return {
          value: file,
          actionRole: ActionRole.PRIMARY
        };
      }));

      this.fileListMetaSig = signal(fileListResponse.meta);

      this.isFileListLoading = false;
    });
  }

  /**
   * Upload a single file received from a file upload event.
   *
   * @param event The file upload event containing the file to upload in a list.
   */
  public handleFileLibraryModalFileUploadEvent(event: IFileUploadItem[]): void {
    if (event.length !== 1) {
      return;
    }

    const fileUploadItem = event[0];

    this.fileUploadService.uploadFile(this.projectId, fileUploadItem.file).pipe(
      catchError(() => this.fileErrorHandlerService.handleUpdateError()),
      tap(fileUploadEvent => fileUploadEvent ? this.fileUploadService.handleFileUploadEvent(fileUploadEvent, fileUploadItem, FileBroadcastEvent.UPLOADED_FILE) : undefined)
    ).subscribe(fileUploadEvent => {
      if (fileUploadEvent?.type !== HttpEventType.Response) {
        return;
      }

      this.currentFile.set(FileService.hydrateFile(fileUploadEvent.body));
      this.animateFilePreview();
    });
  }

  /**
   * On the close event of the file library modal, toggle its visibility and update the background file form controls appropriately.
   *
   * @param event
   */
  public handleFileLibraryModalCloseEvent(event?: IFileHydrated): void {
    this.toggleFileLibraryModalVisibility();

    if (!event) {
      return;
    }

    this.formControl.setValue(event.id);

    // Set file title from default language code.
    this.fileTitle = event.translations[0]?.title;

    this.mimetype = event.mimetype;

    this.initializeFormControls();

    this.fileChangedEvent.emit(event);
  }

  /**
   *
   * @param options
   * @param options.hideSelection
   * @param options.isVisible
   */
  public toggleFileLibraryModalVisibility(options?: { isVisible: boolean, hideSelection: boolean }): void {
    this.isFileLibraryModalVisibleSig.update(isFileLibraryModalVisible => ({
      isVisible: options?.isVisible ?? !isFileLibraryModalVisible.isVisible,
      hideSelection: options?.hideSelection ?? this.hideSelection
    }));

    this.fileLibraryModalEvent.emit(this.isFileLibraryModalVisibleSig().isVisible);
  }

  /**
   * Update the form control for the background file URL based on the active area and the background file ID in the area detail form controls.
   * Also update the form control for the background file title to the file title if provided.
   */
  private initializeFormControls(): void {
    if (!this.projectId || !this.formControl.value) {
      this.currentFile.set(undefined);
      this.fileTitle = '';
      this.backgroundFileTitleFormControl.patchValue(this.fileTitle ?? '');
      this.mimetype = undefined;
      return;
    }

    this.fileListService.getFile(this.projectId, this.formControl.value).pipe(take(1)).subscribe(file => {
      if (!file) {
        return;
      }

      this.backgroundFileUrlFormControl.patchValue(`${this.apiBaseUrl}/projects/${this.projectId}/files/${this.formControl.value}/stream?time=${new Date().getTime()}`);

      this.animateBackgroundThumbnail();

      this.currentFile.set(file);
      this.mimetype = file.mimetype;
      this.fileTitle = file.translations.at(0)?.title;

      this.backgroundFileTitleFormControl.patchValue(this.fileTitle ?? '');
    });
  }

  /**
   * Animate the background thumbnail where the corresponding signal is set to `true` and then reset to `false` after a specified timeout.
   *
   * @param timeoutInMs The timeout im milliseconds to reset the animation signal.
   */
  private animateBackgroundThumbnail(timeoutInMs = 1000): void {
    this.animateFilePreview.set(true);

    setTimeout(() => {
      this.animateFilePreview.set(false);
    }, timeoutInMs);
  }

  /**
   * Build acceptableMimeTypes array to based on the provided media type list.
   * Example: if mediaType is ['image'], this.acceptableMimeTypes will be ['image/*'].
   *
   * @param mediaTypeList
   */
  private buildAcceptableMimeTypes(mediaTypeList: string[]): void {
    this.acceptableMimeTypes.set(mediaTypeList.map(type => `${type}/*`));
  }
}
