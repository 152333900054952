<div class="w-full h-full flex justify-between py-2.5 pr-1 items-center">
  <div class="flex justify-start items-center w-full h-auto lg:w-1/3">
    @if (canNavigateBack) {
      <nrc-button
        class="w-8 h-8 relative mx-2"
        [icon]="'arrow-back'"
        [buttonType]="buttonType.ICON"
        [role]="actionRole.TRANSPARENT_SECONDARY"
        (clickEvent)="backButtonClickEvent.emit()"
      />
    }
    <div class="flex justify-center items-center gap-2 overflow-hidden">
      @for (item of navigationHistory; track item.index; let isLast = $last) {
        @if (item.index === 0) {
        }
        @else {
          @if (item.index > 1) {
              <nrc-icon [name]="'arrow-back'" [fillColor]="'white'" class="rotate-180 transform h-2 w-2"/>
          }

          <div
            class="font-light text-xs leading-tight tracking-wider text-[#fefefe] hover:text-[#fefefe]/80 text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer select-none"
            [ngClass]="{
              'opacity-100': isLast,
              'opacity-50': !isLast,
            }"
            [tabindex]="item.index"
            (click)="navigateToLevel(item.index)"
            (keyup)="null"
          >
            {{ item.label }}
          </div>
          }
        }
    </div>
  </div>

  @if (actionButtons) {
    <div class="flex w-full lg:w-1/3">
      <div class="w-full flex justify-end flex-row items-center min-w-6">
        @if (actionButtons && actionButtons.length > 0) {
          @for (actionButton of actionButtons; track actionButton.id) {
            <nrc-button
              class="w-7 h-7 relative"
              [icon]="actionButton?.icon"
              [label]="actionButton?.label"
              [buttonType]="actionButton?.buttonType"
              [role]="actionButton?.role"
              [tooltipText]="actionButton?.tooltipText"
              [disabled]="actionButton?.disabled"
              (clickEvent)="actionButtonClickEvent.emit(actionButton)"
            />
          }
        }
      </div>
    </div>
  }
</div>
