import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, Signal, ViewChild, computed, forwardRef, signal } from '@angular/core';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { takeUntil } from 'rxjs';
import { GridLayoutAnimationDirective } from '@newroom-connect/library/directives';
import { IActionButton, MediaItem, IFileHydrated, ICardItem, IFileItem } from '@newroom-connect/library/interfaces';
import { ButtonType, ActionRole, MediaType } from '@newroom-connect/library/enums';
import { collapseAnimation, fadeInOutAnimation } from '@newroom-connect/library/animations';

import { ButtonComponent } from '../buttons';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { InputFileComponent } from '../inputs';
import { InputComponent } from '../inputs/input.component';

import { MediaLibraryService } from './services/media-library.service';
import { MediaLibraryCardItemComponent } from './media-library-card-item/media-library-card-item.component';
import { MediaLibraryEmptyComponent } from './media-library-empty/media-library-empty.component';
import { MediaLibraryHeaderComponent } from './media-library-header/media-library-header.component';
import { MediaLibraryItemPropertiesComponent } from './media-library-item-properties/media-library-item-properties.component';

@Component({
  selector: 'nrc-media-library',
  templateUrl: './media-library.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    CheckboxComponent,
    InputFileComponent,
    MediaLibraryEmptyComponent,
    MediaLibraryHeaderComponent,
    MediaLibraryCardItemComponent,
    MediaLibraryItemPropertiesComponent,
    GridLayoutAnimationDirective
  ],
  animations: [collapseAnimation, fadeInOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaLibraryComponent),
      multi: true
    }
  ]
})
export class MediaLibraryComponent extends InputComponent<MediaItem[]> implements AfterViewInit, OnDestroy {
  @Input({ required: true }) public apiBaseUrl!: string;
  @Input({ required: true }) public projectId!: string;

  @Input() public title?: string;
  @Input() public maxCardLevel = 2;

  @ViewChild('gridContainer') public gridContainerElement?: ElementRef<HTMLDivElement>;

  public readonly isFileLibraryVisible = signal<boolean>(false);
  public readonly fileLibraryFormControl = new FormControl<string | null>(null);

  public readonly buttonType = ButtonType;
  public readonly actionRole = ActionRole;
  public readonly mediaType = MediaType;

  /**
   * Computed signal for checking if item selection is active.
   */
  public readonly isItemSelectionActive = computed(() => this.mediaLibraryService.isSelectionModeActive());

  /**
   * Computed signal for header action buttons.
   */
  public readonly headerActionButtons: Signal<IActionButton[]> = computed(() => [
    {
      id: 'delete',
      icon: 'trash',
      buttonType: ButtonType.ICON,
      role: ActionRole.ERROR,
      disabled: !this.mediaLibraryService.isEveryPageItemSelected() && !this.mediaLibraryService.isSomeButNotEveryPageItemSelected()
    },
    {
      id: 'createCard',
      icon: 'folder',
      buttonType: ButtonType.ICON,
      role: ActionRole.TRANSPARENT_SECONDARY,
      disabled: !this.canCreateCard()
    },
    {
      id: 'addFile',
      icon: 'upload',
      buttonType: ButtonType.ICON,
      role: ActionRole.TRANSPARENT_SECONDARY
    }
  ]);

  public readonly canCreateCard = computed(() =>
    this.mediaLibraryService.navigationHistory().length - 1 < this.maxCardLevel
  );

  /**
   * @constructor
   *
   * @param mediaLibraryService
   */
  constructor(public readonly mediaLibraryService: MediaLibraryService) {
    super();
  }

  /**
   * Initialize component after view initialization.
   */
  public override ngAfterViewInit(): void {
    if (this.formControl) {
      this.mediaLibraryService.initialize(this.formControl.value ?? []);

      // Subscribe to form changes from the service
      this.mediaLibraryService.mediaLibraryForm$
        .pipe(takeUntil(this.destroy$))
        .subscribe(form => {
          if (!form || !form.value) {
            return;
          }

          const processedValue = this.processMediaLibraryActionValue(form.value as MediaItem[]);

          this.formControl.patchValue(processedValue, { emitEvent: true });
          this.writeValue(processedValue);
        });
    }
  }

  /**
   * Clean up on component destroy.
   */
  public override ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.mediaLibraryService.clearSelection();
    this.resetInput();
    super.ngOnDestroy();
  }

  /**
   * Handles header action button events.
   * @param actionButton Button that triggered the event.
   */
  public handleHeaderActionButtonEvent(actionButton: IActionButton): void {
    switch (actionButton.id) {
      case 'createCard': {
        this.mediaLibraryService.addItem(MediaType.CARD);
        break;
      }
      case 'delete': {
        const currentCard = this.mediaLibraryService.openedCard();
        const selectedIndices = currentCard.controls
          .map((control, index) => control.get('isSelected')?.value ? index : -1)
          .filter(index => index !== -1);

        this.mediaLibraryService.deleteItems(selectedIndices);
        break;
      }
      case 'addFile': {
        this.toggleFileLibrary();
        break;
      }
      default:
        break;
    }
  }

  /**
   * Handles click event on card items.
   * If item selection is not active, opens the properties bar for the clicked item.
   * If item selection is active, toggles selection for the clicked item.
   *
   * @param index
   */
  public handleCardItemClickEvent(index: number): void {
    this.mediaLibraryService.handleItemSelection(index);
  }

  /**
   * Toggles selection for all items.
   */
  public toggleAllItemsIsSelected(): void {
    const isEverySelected = this.mediaLibraryService.isEveryPageItemSelected();

    this.mediaLibraryService.updateAllItemsSelection(!isEverySelected);
  }

  /**
   * Toggles item selection mode.
   */
  public toggleItemSelection(): void {
    this.mediaLibraryService.toggleSelectionMode();
  }

  /**
   * Handles double click event on card items.
   * @param index Index of the clicked card.
   */
  public handleOpenCardEvent(index: number): void {
    const openedCard = this.mediaLibraryService.openedCard();
    const cardItem = openedCard.at(index);

    if (!cardItem) {
      return;
    }

    this.mediaLibraryService.clearSelection();
    this.mediaLibraryService.navigateToFolder(cardItem);
  }

  /**
   * Handles changes in item properties.
   *
   * @param index
   * @param value
   */
  public handlePropertiesChangeEvent(index: number, value: Partial<MediaItem>): void {
    this.mediaLibraryService.updateItem(index, value);
  }

  /**
   * Handles empty card action event.
   */
  public handleEmptyCardActionEvent(): void {
    if (!this.canCreateCard()) {
      this.toggleFileLibrary();

      return;
    }

    this.mediaLibraryService.addItem(MediaType.CARD);
  }

  /**
   * Handles file library modal close event.
   * @param file Selected file from modal.
   */
  public handleFileLibraryModalCloseEvent(file: IFileHydrated): void {
    if (!this.fileLibraryFormControl) {
      return;
    }

    this.mediaLibraryService.addItem(this.mediaType.FILE, {
      type: MediaType.FILE,
      file,
      fileId: file.id,
      thumbnailId: null,
      fileTags: file.tags,
      label: file.title,
      isSelected: false
    });

    this.toggleFileLibrary();
  }

  /**
   * Reset the input of the form control and the HTML input element.
   */
  private resetInput(): void {
    this.formControl.reset([]);
    this.mediaLibraryService.clearSelection();
    this.mediaLibraryService.isPropertiesCollapsed.set(false);
  }

  /**
   *
   */
  private toggleFileLibrary(): void {
    this.isFileLibraryVisible.update(isVisible => !isVisible);
  }

  /**
   * Processes media library items to strip out file objects and keep only IDs.
   *
   * @param value Array of media items to process.
   *
   * @returns Processed media items with only ID references.
   */
  private processMediaLibraryActionValue(value: MediaItem[]): MediaItem[] {
    const processItem = (item: MediaItem): MediaItem => {
      const { type, label, isSelected, thumbnailId } = item;
      const base = { type, label, isSelected, thumbnailId };

      if (item.type === MediaType.FILE) {
        return {
          ...base,
          fileId: (item as IFileItem).fileId,
          fileTags: (item as IFileItem).fileTags,
          file: (item as IFileItem).file
        } as IFileItem;
      }

      return {
        ...base,
        isThumbnailAsDetailImage: (item as ICardItem).isThumbnailAsDetailImage,
        items: 'items' in item ? item.items?.map(processItem) : []
      } as ICardItem;
    };

    return value.map(processItem);
  }
}
