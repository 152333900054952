import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@newroom-connect/library/pipes';

import { IconComponent } from '../../icon/icon.component';
import { ButtonComponent } from '../../buttons/button/button.component';

@Component({
  selector: 'nrc-media-library-empty',
  templateUrl: './media-library-empty.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslatePipe,
    IconComponent,
    ButtonComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaLibraryEmptyComponent {
  @Input() public canCreateCards = false;

  @Output() public actionButtonClicked = new EventEmitter<void>();
}
