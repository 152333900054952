import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormControlPipe } from '@newroom-connect/library/pipes';

import { IconComponent } from '../../icon/icon.component';
import { InputComponent } from '../input.component';

type NumberMode = 'integer' | 'decimal';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IconComponent, FormControlPipe],
  selector: 'nrc-input-number',
  styleUrls: ['./input-number.component.scss'],
  templateUrl: './input-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true
    }
  ]
})
export class InputNumberComponent extends InputComponent<number> implements OnInit, OnChanges {
  @Input() public value?: number = 0;
  @Input() public min?: number;
  @Input() public max?: number;
  @Input() public step = 0.5;
  @Input() public clearEnabled = true;
  @Input() public readonly = false;
  @Input() public hint?: string;
  @Input() public invalidHint?: string;
  @Input() public mode: NumberMode = 'integer';

  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Initialize the component and set up validators based on min/max constraints.
   */
  public override ngOnInit(): void {
    super.ngOnInit();

    const validators = this.formControl.validator ? [this.formControl.validator] : [];

    if (this.min !== undefined) {
      validators.push(Validators.min(this.min));
    }

    if (this.max !== undefined) {
      validators.push(Validators.max(this.max));
    }

    this.formControl.setValidators(validators);
  }

  /**
   * Handle changes to component inputs.
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['value']) {
      const newValue = this.formatNumber(changes['value'].currentValue);

      this.formControl.patchValue(newValue, { emitEvent: true });
    }

    if (changes && changes['mode']) {
      this.initializeStepValue();

      if (this.formControl.value !== null && this.formControl.value !== undefined) {
        this.formControl.setValue(this.formatNumber(this.formControl.value));
      }
    }
  }

  /**
   *
   */
  public initializeStepValue(): void {
    if (this.mode === 'decimal') {
      this.step = 0.1;
    } else {
      this.step = 1;
    }
  }

  /**
   * Reset the input of the form control to 0.
   */
  public resetInput(): void {
    this.formControl.reset(0);
  }

  /**
   * Increment the current value by the step amount if not disabled and within max bounds.
   */
  public increment(): void {
    if (this.readonly || this.formControl.disabled) {
      return;
    }

    const currentValue = this.formControl.value || 0;
    const newValue = currentValue + this.step;

    if (this.max === undefined || newValue <= this.max) {
      this.formControl.setValue(newValue);
    }
  }

  /**
   * Decrement the current value by the step amount if not disabled and within min bounds.
   */
  public decrement(): void {
    if (this.readonly || this.formControl.disabled) {
      return;
    }

    const currentValue = this.formControl.value || 0;
    const newValue = currentValue - this.step;

    if (this.min === undefined || newValue >= this.min) {
      this.formControl.setValue(newValue);
    }
  }

  /**
   * Get the initial value for the form control.
   *
   * @returns
   */
  protected  getInitialValue(): number {
    return this.value ?? 0;
  }

  /**
   *
   * @param value
   * @returns
   */
  private formatNumber(value: number): number {
    if (this.mode === 'integer') {
      return Math.round(value);
    }

    return Number(`${Math.round(parseFloat(`${value}e3`))}e-3`);
  }
}
