import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({ template: '' })
export abstract class InputComponent<T = any> implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @ViewChild('inputElement') public inputElementRef!: ElementRef<HTMLInputElement>;

  @Input({ required: true }) public formControl!: AbstractControl;
  @Input() public id = '';
  @Input() public name = '';
  @Input() public label: string | unknown = '';
  @Input() public placeholder = ' ';
  @Input() public tabIndex = 0;
  @Input() public disabled = false;
  @Input() public classList: string[] = [];

  protected destroy$ = new Subject<void>();

  /**
   * "OnInit" lifecycle method which injects the control element.
   */
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
  public ngOnInit(): void {
    this.setDisabledState(this.disabled);
  }

  /**
   *
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   *
   */
  public ngAfterViewInit(): void {
    // Remove existing background classes since they cannot be overridden.
    if (this.classList.some(className => className.startsWith('bg-'))) {
      this.inputElementRef.nativeElement.classList.forEach(elementClassName => {
        if (elementClassName.startsWith('bg-')) {
          this.inputElementRef.nativeElement.classList.remove(elementClassName);
        }
      });
    }

    for (const className of this.classList) {
      if (!this.inputElementRef.nativeElement.classList.contains(className)) {
        this.inputElementRef.nativeElement.classList.add(className);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onChange: any = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onTouched: () => void = () => {};

  /**
   *
   * @param value
   */
  public writeValue(value: T): void {
    value && this.formControl.setValue(value, { emitEvent: false, emitModelToViewChange: false });
  }

  /**
   *
   * @param fn
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   *
   * @param fn
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   *
   * @param isDisabled
   */
  public setDisabledState(isDisabled: boolean): void {
    const currentDisabledState = this.formControl.disabled;

    if (isDisabled !== currentDisabledState) {
      if (isDisabled) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    }
  }

  /**
   * Handle the input value changes.
   *
   * @param event The event of the input change containing the changed value.
   */
  public handleInputChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    this.onChange(value);
    this.onTouched();
  }
}
