export enum SupportedCrossAppEventType {
  HTML_BACKGROUND = 'HTML_BACKGROUND',
  PIXEL_STREAM = 'PIXEL_STREAM'
}

export enum SupportActionEvents {
  ACTIVATE_AREA_ELEMENT = 'ACTIVATE_AREA_ELEMENT'
}

export interface ICrossAppMessageData {
  action: SupportActionEvents;
  areaElementId?: string;

  // Add additional properties as needed.
  [key: string]: any;
}

export interface ICrossAppMessage {
  type: SupportedCrossAppEventType;
  data: ICrossAppMessageData;
  source?: string;
  timestamp?: number;
}

export interface ICrossAppMessageHandler {
  type: string;
  callback: (message: ICrossAppMessage) => void;
}
