<div
  #areaElementWrapper
  tabindex="-1"
  class="relative w-full h-full"
  nrcDoubleClick
  (doubleClickEvent)="handleAreaElementDblClick()"
  (click)="handleAreaElementClick($event)"
  (keyup)="null"
>
  <div
    class="relative flex items-center justify-center w-full h-full transition-all group hover:cursor-pointer"
    tabindex="-1"
    [ngClass]="animation()"
    [style.animation-duration]="areaElement.animation?.duration + 'ms'"
    [style.animation-iteration-count]="areaElement.animation?.isInfinite ? 'infinite' : '1'"
    [style.backgroundColor]="areaElement.baseColor"
    [style.border-radius]="areaElement.radius + 'px'"
    [style.box-shadow]="boxShadow()"
    (keyup)="null"
  >
    @if (backgroundFile()) {
      <nrc-media-preview
        class="absolute top-0 left-0 z-10 object-cover w-full h-full overflow-hidden"
        [ngClass]="{
          'bg-white/5': !backgroundFile()
        }"
        [style.border-radius]="areaElement.radius + 'px'"
        [style.opacity]="backgroundFileOpacity()"
        [mimetype]="backgroundFile() ? backgroundFile()!.mimetype : 'image/png'"
        [objectFit]="'fill'"
        [source]="backgroundFile() ? apiBaseUrl + '/projects/' + backgroundFile()!.projectId + '/files/' + backgroundFile()!.id + '/stream' : undefined"
        [mediaPreviewTypes]="['image', 'video-snippet']"
      />
    }

    <div class="absolute inset-0 z-20 flex items-center overflow-hidden">
      <!-- Content Wrapper -->
      <div
        class="flex w-full h-full px-2 transition-transform duration-500 ease-in-out"
        [ngClass]="{
          'justify-start': areaElement.iconPosition === 'LEFT',
          'justify-center': !areaElement.iconPosition || areaElement.iconPosition === 'CENTER',
          'justify-end': areaElement.iconPosition === 'RIGHT'
        }"
      >
        <!-- Dynamic Layout Container -->
        <div
          class="flex flex-col items-center justify-center w-full h-full transition-all duration-500 ease-in-out transform"
          [ngClass]="{
            'flex-col': areaElement.iconPosition === 'CENTER',
            'gap-2': textOverlay() && areaElement.iconSize > 50,
            'gap-3': textOverlay() && areaElement.iconSize <= 50,
            'gap-1': textOverlay() && areaElement.iconSize <= 10
          }"
        >
          @if (areaElement.icon && areaElement.icon !== 'empty' && (areaElement.iconPosition === 'LEFT' || areaElement.iconPosition === 'CENTER')) {
            <div class="flex items-center justify-center w-auto aspect-square" [style.height]="areaElement.iconSize + '%'">
              <nrc-icon
                class="w-full h-full transition-transform duration-500 ease-in-out hover:scale-110"
                [name]="areaElement.icon"
                [fillColorHex]="areaElement.iconColor ?? '#ffffff'"
              />
            </div>
          }

          @if (textOverlay()) {
            <div
              class="flex items-center w-full overflow-hidden transition-all duration-500 ease-in-out opacity-95 hover:opacity-100"
            >
              <div
                class="block w-full h-full text-nowrap prose-invert"
                [innerHTML]="textOverlay()"
              ></div>
            </div>
          }

          @if (areaElement.icon && areaElement.iconPosition === 'RIGHT') {
            <div class="flex items-center justify-center w-auto aspect-square" [style.height]="areaElement.iconSize + '%'">
              <nrc-icon
                class="w-full h-full transition-transform duration-500 ease-in-out hover:scale-110"
                [name]="areaElement.icon"
                [fillColorHex]="areaElement.iconColor ?? '#ffffff'"
              />
            </div>
          }
        </div>
      </div>
    </div>

    @if (tooltipText()) {
      <nrc-tooltip
        class="tooltip tooltip-group"
        [text]="tooltipText()!"
      />
    }

    <!-- Display icon in the upper-right corner of the area element, if it is locked. -->
    @if (areaElement.isLocked) {
      <nrc-icon
        class="absolute z-20 w-3 h-3 max-w-full max-h-full top-2 right-2"
        [name]="'lock-closed'"
      />
    }
  </div>

  <!-- Drag points for resizing the area element -->
  @if (isSelected() && isResizable && !areaElement.isLocked) {
    <div class="absolute top-0 left-0 z-20 w-2 h-2 bg-white rounded-full hover:cursor-grab active:cursor-grabbing"></div>
    <div class="absolute bottom-0 left-0 z-20 w-2 h-2 bg-white rounded-full hover:cursor-grab active:cursor-grabbing"></div>
    <div class="absolute top-0 right-0 z-20 w-2 h-2 bg-white rounded-full hover:cursor-grab active:cursor-grabbing"></div>
    <div class="absolute bottom-0 right-0 z-20 w-2 h-2 bg-white rounded-full hover:cursor-grab active:cursor-grabbing"></div>
  }
</div>
