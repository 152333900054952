@if (isLoading$ | async) {
  <nrc-loading
    class="fixed z-50 w-full h-full bg-white/25"
  />
}

@if (toastMessage()) {
  <nrc-toast
    @fadeInOutAnimation
    class="fixed z-50 bottom-10 right-10"
    [toastMessage]="toastMessage()"
  />
}

@if (alertMessage()) {
  <nrc-alert
    @fadeInOutAnimation
    [message]="alertMessage()"
    [confirmButton]="{ label: 'Ok' }"
    (confirmEvent)="alertService.resetMessage()"
  />
}

@if (showLogo()) {
  <nrc-image
    class="fixed top-0 left-0 z-10 w-16 mt-8 ml-8"
    [source]="{ uri: '../assets/images/nrc_logo_white.svg' }"
    [alt]="'NRC Logo'"
    [isTransparent]="true"
  />
}


@if (fileService.downloadProgress()) {
  <nrc-file-download-status
    class="fixed z-50 bottom-5 right-4 min-w-72"
    [downloadProgress]="fileService.downloadProgress()"
  />
}

<ng-container #modalContainer></ng-container>

<div class="flex flex-col items-center justify-center w-full h-full overflow-y-auto">
  @if (!showLogo()) {
    <nrc-virtual-studio-header
      class="block w-screen bg-[#404040] shadow-[0_5px_10px_#00000033] z-10 sticky top-0"
      [headerItems]="[
        { title: 'Home', icon: 'home', route: '/home' },
        { title: 'Projects', icon: 'rocket', route: '/projects' },
        { title: 'Areas', icon: 'area', route: '/areas', disabled: !this.currentProject() },
        { title: 'File Library', icon: 'folder', route: '/files', disabled: !this.currentProject() },
        { title: 'Visitor Directory', icon: 'users', route: '/404', disabled: true },
        { title: 'Chat', icon: 'chat', route: '/404', disabled: true },
        { title: 'E-Mails', icon: 'mail', route: '/404', disabled: true }
      ]"
      (logoutEvent)="handleLogout()"
    />
  }

  <div class="flex flex-col w-full overflow-hidden grow">
    <router-outlet></router-outlet>
  </div>
</div>

@if (!isProdEnvironment) {
  <span class="fixed bottom-2 left-2 text-xs font-extralight [text-shadow:_0_0.5px_3.5px_rgb(0_0_0_/_0.8)] z-50">
    {{ appVersion }}
  </span>
}
