<div class="flex flex-col justify-between h-full gap-4">
  <div nrcReorder (reorderEvent)="handleReorderEvent($event)" class="relative flex flex-col gap-4 p-4">
    <span class="text-sm text-white/50">Form Builder</span>

    <div class="flex flex-col gap-1">
      @for (inputControl of formBuilderInputs.controls; track inputControlIndex; let inputControlIndex = $index) {
        <div nrcReOrderableItem [grabIcon]="true" class="pt-2">
          <nrc-collapsible
            class="relative flex flex-col w-full p-4 space-y-2 overflow-visible rounded bg-neutral-800"
            [label]="inputControl.controls.label.value"
            [actionButtons]="[{id: 'delete', icon: 'trash'}]"
            [icon]="supportedInputTypesRecord[inputControl.controls.type.value].icon"
            [isCollapsed]="true"
            (actionButtonClicked)="handleActionButtonEvent($event, inputControlIndex)"
          >
            <div class="flex flex-col">
              <div class="pt-2">
                <nrc-input-radio
                  [formControl]="inputControl.controls.type"
                  [options]="supportedInputTypesOptions"
                  [type]="radioInputType.COLUMNS"
                />
              </div>
              <hr class="w-full h-[1.5px] my-4 bg-white/10 border-none">
            </div>

            @switch (inputControl.controls.type.value) {
              @case (supportedInputTypes.TEXT) {
                <div class="flex flex-col space-y-4">
                  <div class="flex items-center w-full space-x-4">
                    <nrc-input-text [formControl]="inputControl.controls.label" [type]="'text'" class="w-1/2" placeholder="'Label'" [label]="'Label'"/>
                    <nrc-input-select
                      class="w-1/2 h-10"
                      [formControl]="inputControl.controls.placement"
                      [name]="'list-items-per-page'"
                      [id]="'list-items-per-page'"
                      [options]="[{label: 'Full Row', value: 'FULL'}, {label: 'Left', value: 'LEFT'}]"
                      [labelProperty]="'label'"
                      [valueProperty]="'value'"
                      [isRound]="false"
                      [searchEnabled]="false"
                      [label]="'Placement'"
                    />
                  </div>

                  <div class="flex items-center space-x-4">
                    <nrc-input-checkbox
                      class="ml-1"
                      [formControl]="inputControl.controls.required"
                      [id]="'isRequired' + inputControlIndex"
                      [label]="'Required'"
                    />

                    @if (inputControl.controls.isTextArea) {
                      <nrc-input-checkbox
                        class="ml-1"
                        [formControl]="inputControl.controls.isTextArea"
                        [id]="'isTextArea' + inputControlIndex"
                        [label]="'Text Area'"
                      />
                    }
                  </div>
                </div>
              }

              @case (supportedInputTypes.SLIDER) {
                <div class="flex flex-col space-y-4">
                  <nrc-input-text [formControl]="inputControl.controls.label" [type]="'text'" class="w-1/2" placeholder="'Label'" [label]="'Label'"/>

                  <div class="flex space-x-4">
                    @if (inputControl.controls.minimum && inputControl.controls.maximum) {
                      <nrc-input-text [formControl]="inputControl.controls.minimum" [type]="'number'" class="w-1/2" placeholder="'Label'" [label]="'Minimum'"/>
                      <nrc-input-text [formControl]="inputControl.controls.maximum" [type]="'number'" class="w-1/2" placeholder="'Label'" [label]="'Maximum'"/>
                    }
                  </div>
                </div>
              }

              @case (supportedInputTypes.DATE) {
                <ng-container *ngTemplateOutlet="commonDateTimeInputTemplate; context: { type: inputControl.controls.type.value }"></ng-container>
              }

              @case (supportedInputTypes.TIME) {
                <ng-container *ngTemplateOutlet="commonDateTimeInputTemplate; context: { type: inputControl.controls.type.value }"></ng-container>
              }

              @case (supportedInputTypes.DROPDOWN) {
                <ng-container *ngTemplateOutlet="commonOptionInputTemplate; context: { type: inputControl.controls.type.value }"></ng-container>
              }

              @case (supportedInputTypes.CHECKBOX) {
                <ng-container *ngTemplateOutlet="commonOptionInputTemplate; context: { type: inputControl.controls.type.value }"></ng-container>
              }

              @case (supportedInputTypes.RADIO_BUTTON) {
                <ng-container *ngTemplateOutlet="commonOptionInputTemplate; context: { type: inputControl.controls.type.value }"></ng-container>
              }
            }

            <ng-template #commonOptionInputTemplate let-type="type">
              <div class="flex flex-col space-y-4">
                <div class="flex items-center w-full space-x-4">
                  <nrc-input-text
                    class="w-1/2"
                    placeholder="'Label'"
                    [formControl]="inputControl.controls.label"
                    [type]="'text'"
                    [label]="'Label'"
                  />
                </div>

                @if (inputControl.controls.options) {
                  <div class="grid grid-cols-2 gap-4">
                    @for (optionControl of inputControl.controls.options.controls; track optionControlIndex; let optionControlIndex = $index) {
                      <div class="flex items-center justify-between space-x-2">
                        <nrc-input-text
                          class="w-full"
                          [formControl]="optionControl.controls.label"
                          [type]="'text'"
                          [label]="'Option #' + (optionControlIndex + 1) + ' Label'"
                          [placeholder]="'Option #' + (optionControlIndex + 1) + ' Label'"
                        />

                      <nrc-button
                        class="relative w-6 h-6"
                        [icon]="'trash'"
                        [buttonType]="buttonType.ICON"
                        [role]="buttonActionRole.ERROR"
                        (clickEvent)="handelOptionDelete(inputControl.controls.options, optionControlIndex)"
                      />
                      </div>
                    }

                    <div class="flex items-center">
                      <nrc-button
                        class="flex w-50"
                        [label]="'Add option'"
                        [icon]="'plus'"
                        [buttonType]="buttonType.DEFAULT"
                        [role]="buttonActionRole.TRANSPARENT_PRIMARY"
                        [fontWeight]="'bold'"
                        (clickEvent)="addNewOptionInputForm(inputControl.controls.options)"
                      />
                    </div>
                  </div>
                }

                <div class="flex items-center">
                  <nrc-input-checkbox
                    class="ml-1"
                    [formControl]="inputControl.controls.required"
                    [id]="'isRequired' + inputControlIndex"
                    [label]="'Required'"
                  />
                </div>
              </div>
            </ng-template>

            <ng-template  #commonDateTimeInputTemplate let-type="type">
              <div class="flex flex-col space-y-4">
                <div class="flex items-center w-full space-x-4">
                  <nrc-input-text
                    class="w-1/2"
                    placeholder="'Label'"
                    [formControl]="inputControl.controls.label"
                    [label]="'Label'"
                    [type]="'text'"
                  />
                </div>

                <div class="flex items-center w-full space-x-4">
                  @if (inputControl.controls.rangeStart && inputControl.controls.isRange?.value) {
                    @if (inputControl.controls.type.value === supportedInputTypes.DATE) {
                      <nrc-input-datepicker
                        class="w-1/2"
                        placeholder="'To'"
                        [formControl]="inputControl.controls.rangeStart"
                        [label]="'To'"
                      />
                    }

                    @if (inputControl.controls.type.value === supportedInputTypes.TIME) {
                      <nrc-input-timepicker
                        class="w-1/2"
                        placeholder="'To'"
                        [formControl]="inputControl.controls.rangeStart"
                        [label]="'To'"
                      />
                    }
                  }

                  @if (inputControl.controls.rangeEnd && inputControl.controls.isRange?.value) {
                    @if (inputControl.controls.type.value === supportedInputTypes.DATE) {
                      <nrc-input-datepicker
                        class="w-1/2"
                        placeholder="'To'"
                        [formControl]="inputControl.controls.rangeEnd"
                        [label]="'To'"
                      />
                    }

                    @if (inputControl.controls.type.value === supportedInputTypes.TIME) {
                      <nrc-input-timepicker
                        class="w-1/2"
                        placeholder="'To'"
                        [formControl]="inputControl.controls.rangeEnd"
                        [label]="'To'"
                      />
                    }
                  }
                </div>

                <div class="flex items-center space-x-4">
                  <nrc-input-checkbox
                    class="ml-1"
                    [formControl]="inputControl.controls.required"
                    [id]="'isRequired' + inputControlIndex"
                    [label]="'Required'"
                  />

                  @if (inputControl.controls.isRange) {
                    <nrc-input-checkbox
                      class="ml-1"
                      [formControl]="inputControl.controls.isRange"
                      [id]="'isRange' + inputControlIndex"
                      [label]="'Range'"
                    />
                }
                </div>
              </div>
            </ng-template>
          </nrc-collapsible>
        </div>
      }
    </div>
  </div>

  <div class="sticky bottom-0 w-full bg-neutral-800">
    <nrc-button
      class="flex w-40 p-2"
      [label]="'Add Input'"
      [icon]="'plus'"
      [buttonType]="buttonType.DEFAULT"
      [role]="buttonActionRole.TRANSPARENT_PRIMARY"
      [fontWeight]="'bold'"
      (clickEvent)="createFormBuilderInputForm(formBuilderInputs.length)"
    />
  </div>
</div>
