<div
  class="relative flex flex-col items-center justify-center w-full h-full gap-2 p-2 transition-opacity rounded-md grow group hover:bg-white/10 hover:cursor-pointer"
  nrcHoverDelay
  [debounceTimeMouseLeave]="100"
  [targetId]="'contextMenuContainer-' + uniqueHash"
  [ngClass]="{
    'opacity-50 hover:cursor-not-allowed pointer-events-none select-none': disabled,
    'hover:cursor-pointer': !disabled && !contextMenu,
    'hover:cursor-default': contextMenu
  }"
  tabindex="0"
  [attr.disabled]="this.disabled"
  (click)="handleClickEvent()"
  (keyup)="null"
>

  <nrc-icon
    class="inline-block w-full"
    [ngClass]="{
      'h-full': !label,
      'h-1/2': label
    }"
    [name]="icon"
  />

  @if (label) {
    <span class="text-xs text-center text-[#898989] font-light flex justify-center items-center">
      {{ label }}
    </span>
  }

  @if (tooltipText) {
    <nrc-tooltip
      class="absolute z-20 w-20 mt-2 -ml-10 transition-all scale-0 opacity-0 min-h-5 left-1/2 group-hover:opacity-100 group-hover:scale-100"
      [ngClass]="{
        '-top-full': tooltipTextTopside,
        'top-full': !tooltipTextTopside
      }"
      [tooltipTextTopside]="tooltipTextTopside"
      [text]="tooltipText"
    />
  }

  @if (contextMenu && !disabled) {
    <div
      class="absolute z-20 hidden transition ease-in-out -translate-y-[4.5rem] rounded-lg bg-transparent-dark duration-250 delay-250"
      [id]="'contextMenuContainer-' + uniqueHash"
    >
      <div class="grid grid-cols-1 divide-y divide-[#2d2d2d] w-40 justify-center items-start">
        @for (actionButton of contextMenu; track actionButton.id) {
          <div class="flex flex-row items-center justify-between px-4 py-2 cursor-pointer">
            <nrc-action-button-menu-item
              class="flex justify-between w-full"
              [icon]="actionButton?.icon"
              [label]="actionButton?.label"
              [role]="actionButton?.role"
              (click)="handleContextMenuClickEvent($event, actionButton)"
            />
          </div>
        }
      </div>
    </div>
  }
</div>
