<div class="relative flex items-center justify-center w-full h-full">
  @if (backgroundFile) {
    @if (isBackgroundFileCubemapSig()) {
      <nrc-area-background-360-degree
        class="absolute top-0 left-0 w-full h-full"
        [apiBaseUrl]="apiBaseUrl"
        [backgroundFile]="backgroundFile"
        [overlays]="areaElements"
      />
    } @else if (isBackgroundFileHTMLSig()) {
      <nrc-html-background
        class="absolute top-0 left-0 w-full h-full"
        [backgroundFile]="backgroundFile"
        [apiBaseUrl]="apiBaseUrl"
      />
    }
    @else {
      <nrc-area-background-2d
        class="absolute top-0 left-0 w-full h-full"
        [apiBaseUrl]="apiBaseUrl"
        [backgroundFile]="backgroundFile"
        [isEditMode]="isEditMode"
        (loadComplete)="onBackgroundLoadComplete()"
      />
    }
  } @else {
    <nrc-area-background-empty
      (changeBackgroundEvent)="changeBackgroundEvent.emit()"
    />
  }
</div>
