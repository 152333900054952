import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { merge, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';

import { InputComponent } from '../input.component';

interface IMultiRangeValue {
  min: number;
  max: number;
}

@Component({
  selector: 'nrc-input-multi-range',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './input-multi-range.component.html',
  styleUrls: ['./input-multi-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputMultiRangeComponent),
      multi: true
    }
  ]
})
export class InputMultiRangeComponent extends InputComponent<number> implements AfterViewInit, OnChanges {
  private static readonly CYAN_HIGHLIGHT_HEX_COLOR = '#00cfff';

  @ViewChild('minRangeElement') public minRangeElement!: ElementRef<HTMLInputElement>;
  @ViewChild('maxRangeElement') public maxRangeElement!: ElementRef<HTMLInputElement>;

  @Input({ required: true }) public minLimit!: number;
  @Input({ required: true }) public maxLimit!: number;
  @Input() public isLabelEnabled = true;

  @Output() public valueChange = new EventEmitter<IMultiRangeValue>();

  public minControl = new FormControl<number>(this.minLimit ?? 0, { nonNullable: true });
  public maxControl = new FormControl<number>(this.maxLimit ?? 100, { nonNullable: true });

  /**
   *
   */
  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    merge(
      this.minControl.valueChanges,
      this.maxControl.valueChanges
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateRangeValues());
  }

  /**
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['minLimit'] || changes['maxLimit']) {
      this.minControl.patchValue(this.minLimit);
      this.maxControl.patchValue(this.maxLimit);
    }
  }

  /**
   * Handle input events from the minimum "range slider".
   * Ensures that the minimum value does not exceed the maximum value.
   *
   * @param event
   */
  public onMinRangeChange(event: Event): void {
    const value = Number((event.target as HTMLInputElement).value);

    if (value >= this.maxControl.value) {
      this.minControl.setValue(value);
    }

    this.updateRangeValues();
  }

  /**
   * Handle input events from the maximum "range slider".
   * Ensures that the maximum value does not fall below the minimum value.
   *
   * @param event
   */
  public onMaxRangeChange(event: Event): void {
    const value = Number((event.target as HTMLInputElement).value);

    if (value <= this.minControl.value) {
      this.maxControl.setValue(value);
    }

    this.updateRangeValues();
  }

  /**
   * Handles change events from the minimum value "number input".
   * Validates and constrains the input value within allowed range.
   *
   * @param event
   */
  public onMinRangeInput(event: Event): void {
    let value = Number((event.target as HTMLInputElement).value);

    if (isNaN(value)) {
      value = this.minLimit;
    }

    value = Math.max(this.minLimit, Math.min(this.maxControl.value, value));
    this.minControl.patchValue(value);
  }

  /**
   * Handles change events from the maximum value "number input".
   * Validates and constrains the input value within allowed range.
   *
   * @param event
   */
  public onMaxRangeInput(event: Event): void {
    let value = Number((event.target as HTMLInputElement).value);

    if (isNaN(value)) {
      value = this.maxLimit;
    }

    value = Math.max(this.minControl.value, Math.min(this.maxLimit, value));
    this.maxControl.patchValue(value);
  }

  /**
   * Updates the range values and emits the change event.
   * This is called whenever either the min or max value changes.
   *
   */
  public updateRangeValues(): void {
    if (!this.minRangeElement || !this.maxRangeElement) {
      return;
    }

    const minValue = this.minControl.value;
    const maxValue = this.maxControl.value;

    this.valueChange.emit({ min: minValue, max: maxValue });

    // Wrap the update range slider position in requestAnimationFrame to prevent layout issues.
    requestAnimationFrame(() => {
      this.updateRangeSliderPositions();
    });
  }

  /**
   * Updates the range slider positions and background.
   *
   */
  private updateRangeSliderPositions(): void {
    if (!this.minRangeElement || !this.maxRangeElement) {
      return;
    }

    const range = this.maxLimit - this.minLimit;
    const minValue = this.minControl.value;
    const maxValue = this.maxControl.value;

    // Calculate percentages for the background gradient
    const minPercent = ((minValue - this.minLimit) / range) * 100;
    const maxPercent = ((maxValue - this.minLimit) / range) * 100;

    // Update slider background gradient
    const backgroundGradient = `linear-gradient(to right,
      #ffffff0d ${minPercent}%,
      ${InputMultiRangeComponent.CYAN_HIGHLIGHT_HEX_COLOR} ${minPercent}%,
      ${InputMultiRangeComponent.CYAN_HIGHLIGHT_HEX_COLOR} ${maxPercent}%,
      #ffffff0d ${maxPercent}%
    )`;

    // Update both range inputs
    this.minRangeElement.nativeElement.style.background = backgroundGradient;
    this.maxRangeElement.nativeElement.style.background = backgroundGradient;

    // Ensure the range input values are updated
    this.minRangeElement.nativeElement.value = minValue.toString();
    this.maxRangeElement.nativeElement.value = maxValue.toString();
  }
}
