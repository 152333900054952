import { Component, Input, Renderer2, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { take } from 'rxjs';
import { FileService, LoggingService } from '@newroom-connect/library/services';
import { IFile } from '@newroom-connect/library/interfaces';
import { FileHelper } from '@newroom-connect/library/helpers';

  @Component({
    selector: 'nrc-html-background',
    standalone: true,
    templateUrl: './html-background.component.html'
  })
export class HtmlBackgroundComponent implements AfterViewInit {
    @ViewChild('backgroundContainer') public backgroundContainer?: ElementRef<HTMLDivElement>;

    @Input({ required: true }) public apiBaseUrl = '';
    @Input({ required: true }) public backgroundFile!: IFile;

    public iframeElement: HTMLIFrameElement | null = null;

    /**
     *
     * @param fileService
     * @param loggingService
     * @param renderer
     */
    constructor(
      private readonly fileService: FileService,
      private readonly loggingService: LoggingService,
      private readonly renderer: Renderer2
    ) {}

    /**
     *
     */
    public ngAfterViewInit(): void {
      this.loadBackgroundHtml(this.backgroundFile);
    }

    /**
     * Loads the HTML background file.
     *
     * @param backgroundFile The file containing the HTML content.
     */
    public loadBackgroundHtml(backgroundFile: IFile): void {
      const sourceUrl = FileHelper.getFileSourceURI(this.apiBaseUrl, backgroundFile);

      this.fileService.getFileContent(sourceUrl).pipe(take(1)).subscribe({
        next: result => {
          this.loggingService.debug('File Content available:', result);
          if (!this.backgroundContainer) {
            return;
          }

          this.loadContent(result);
        },
        error: err => {
          this.loggingService.error('Error loading file content:', err);
        }
      });
    }

    /**
     * Loads the content of the HTML file into an iframe.
     *
     * @param htmlContent The HTML content to load.
     */
    private loadContent(htmlContent: string): void {
      this.iframeElement = this.renderer.createElement('iframe');

      if (!this.iframeElement || !this.backgroundContainer) {
        return;
      }

      this.iframeElement.sandbox.add('allow-scripts');
      this.iframeElement.sandbox.add('allow-same-origin');

      const blob = new Blob([htmlContent], { type: 'text/html' });
      const blobURL = URL.createObjectURL(blob);

      this.iframeElement.onload = (): void => {
        URL.revokeObjectURL(blobURL);
      };

      this.iframeElement.src = blobURL;
      this.iframeElement.classList.add('w-full');
      this.iframeElement.classList.add('h-full');
      this.iframeElement.classList.add('aspect-video');

      this.backgroundContainer.nativeElement.appendChild(this.iframeElement);
    }
}
