import { BOOLEAN_RULES, DATE_RULES, FilterPropertyType, FilterRuleType, IFilterConditionPreset, IFilterPropertyOption, NUMBER_RULES, RELATION_RULES, STRING_RULES } from '@newroom-connect/library/interfaces';

export const DEFAULT_RULES_BY_TYPE: Record<FilterPropertyType, FilterRuleType[]> = {
  string: STRING_RULES,
  number: NUMBER_RULES,
  boolean: BOOLEAN_RULES,
  date: DATE_RULES,
  relation: RELATION_RULES
};

export class FilterConditionMapHelper {
  /**
   * Get filter options for a specific collection.
   *
   * @param collectionName
   * @param metadata
   *
   * @returns
   */
  public static extractFilterOptionsForCollection(collectionName: string, metadata: IFilterConditionPreset[]): IFilterPropertyOption[] {
    return metadata.find(m => m.collection === collectionName)?.filterOptions ?? [];
  }

  /**
   * Validate if a filter rule is valid for a property type.
   *
   * @param rule
   * @param type
   *
   * @returns
   */
  public static isRuleValidForType(rule: FilterRuleType, type: FilterPropertyType): boolean {
    return DEFAULT_RULES_BY_TYPE[type].includes(rule);
  }

  /**
   * Get available rules for a property type.
   *
   * @param type
   *
   * @returns
   */
  public static getRulesForType(type: FilterPropertyType): FilterRuleType[] {
    return DEFAULT_RULES_BY_TYPE[type];
  }
}
