import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild, signal } from '@angular/core';
import { Observable, from, map, take } from 'rxjs';
import { CubeTextureLoader, PerspectiveCamera, Scene, WebGLRenderer, Vector3 } from 'three';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TranslatePipe } from '@newroom-connect/library/pipes';

export type CubemapFaceMap = {
  px: string;
  py: string;
  pz: string;
  nx: string;
  ny: string;
  nz: string;
}

export type CubemapBackgroundLoadingStatus = 'loading' | 'failed' | 'success';

/**
 * Interface defining the structure of HTML elements to be rendered in 3D space.
 */
export interface HTMLOverlay {
  element: HTMLElement;
  position: Vector3;
}

@Component({
  selector: 'nrc-cubemap',
  standalone: true,
  imports: [TranslatePipe],
  templateUrl: './cubemap.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CubemapComponent implements AfterViewInit, OnChanges {
  private static readonly DEFAULT_CAMERA_FOV = 75;
  private static readonly DEFAULT_CAMERA_FRUSTUM_NEAR = 0.1;
  private static readonly DEFAULT_CAMERA_FRUSTUM_FAR = 1000;

  @ViewChild('cubemapCanvasContainer') private cubemapCanvasContainerRef!: ElementRef<HTMLDivElement>;
  @ViewChild('cubemapCanvas') private cubemapCanvasRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('css3dContainer') private css3dContainerRef!: ElementRef<HTMLDivElement>;

  @Input({ required: true }) public faceMap!: CubemapFaceMap;
  @Input() public htmlOverlays: HTMLOverlay[] = [];

  public backgroundLoadingStatusSig = signal<CubemapBackgroundLoadingStatus>('loading');
  public backgroundLoadingProgressSig = signal<number>(0);

  private camera!: PerspectiveCamera;
  private controls!: OrbitControls;
  private renderer!: WebGLRenderer;
  private css3dRenderer!: CSS3DRenderer;
  private scene?: Scene;
  private cssScene: Scene;
  private htmlObjects: CSS3DObject[] = [];
  private isAfterViewInitFinished = false;

  /**
   *
   * @constructor
   */
  constructor() {
    this.cssScene = new Scene();
  }

  /**
   * Create the scene and start the rendering loop after the view has been initialized.
   */
  public ngAfterViewInit(): void {
    this.createScene().pipe(take(1)).subscribe(() => {
      this.startRenderingLoop();

      this.isAfterViewInitFinished = true;
    });
  }

  /**
   * Handle changes to inputs, updating the scene background and HTML elements as needed.
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    // If the face map changed, update the scene background and size of the scene.
    if (changes['faceMap']?.currentValue && this.isAfterViewInitFinished) {
      from(this.setSceneBackground()).pipe(take(1)).subscribe(() => {
        this.updateSize();
      });
    }

    // If HTML overlays changed, update the 3D HTML elements in the scene
    if (changes['htmlOverlays']?.currentValue && this.isAfterViewInitFinished) {
      this.updateHTMLElements();
    }
  }

  /**
   * Update the size of the scene when the window is resized.
   */
  @HostListener('window:resize')
  public onWindowResize(): void {
    this.updateSize();
  }

  /**
   * Create the cubemap scene where the cube texture is loaded and set as background of the scene.
   * The camera is created and positioned afterwards.
   *
   * @returns Observable that completes when the scene is created.
   */
  private createScene(): Observable<void> {
    this.scene = new Scene();

    return from(this.setSceneBackground()).pipe(
      map(() => {
        // Create the camera in the scene based on the current aspect ratio of the canvas and some default values.
        this.camera = new PerspectiveCamera(
          CubemapComponent.DEFAULT_CAMERA_FOV,
          this.getAspectRatio(),
          CubemapComponent.DEFAULT_CAMERA_FRUSTUM_NEAR,
          CubemapComponent.DEFAULT_CAMERA_FRUSTUM_FAR
        );

        this.camera.position.z = 1;
      })
    );
  }

  /**
   * Create a new WebGL renderer on the canvas and an instance for the orbit controls and start the rendering loop.
   * Also initializes the CSS3D renderer for HTML elements.
   */
  private startRenderingLoop(): void {
    // Initialize WebGL renderer
    this.renderer = new WebGLRenderer({ canvas: this.cubemapCanvasRef.nativeElement });
    this.renderer.setSize(this.cubemapCanvasContainerRef.nativeElement.clientWidth, this.cubemapCanvasContainerRef.nativeElement.clientHeight);

    // Initialize CSS3D renderer
    this.css3dRenderer = new CSS3DRenderer();
    this.css3dRenderer.setSize(this.cubemapCanvasContainerRef.nativeElement.clientWidth, this.cubemapCanvasContainerRef.nativeElement.clientHeight);
    this.css3dContainerRef.nativeElement.appendChild(this.css3dRenderer.domElement);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.controls.update();

    // Initialize HTML elements
    this.updateHTMLElements();

    // Define the rendering loop.
    const render = (): void => {
      requestAnimationFrame(render);

      if (this.scene) {
        this.renderer.render(this.scene, this.camera);
        this.css3dRenderer.render(this.cssScene, this.camera);
      }
    };

    // Start the rendering loop.
    render();
  }

  /**
   * Update the HTML elements in the CSS3D scene.
   * Removes existing elements and adds new ones based on the current htmlOverlays input.
   */
  private updateHTMLElements(): void {
    // Remove existing HTML objects from the scene
    this.htmlObjects.forEach(object => this.cssScene.remove(object));

    this.htmlObjects = [];

    // Add new HTML objects to the scene
    this.htmlOverlays.forEach(overlay => {
      const object = new CSS3DObject(overlay.element);

      object.position.copy(overlay.position);

      this.htmlObjects.push(object);
      this.cssScene.add(object);
    });
  }

  /**
   * Get the current aspect ratio of the component's canvas on the client.
   *
   * @returns The aspect ratio of the component's canvas on the client.
   */
  private getAspectRatio(): number {
    return this.cubemapCanvasContainerRef.nativeElement.clientWidth / this.cubemapCanvasRef.nativeElement.clientHeight;
  }

  /**
   * Update the aspect ratio of the component's camera and the width/height of the renderer
   * based on the size of the canvas container.
   */
  private updateSize(): void {
    this.camera.aspect = this.getAspectRatio();
    this.camera.updateProjectionMatrix();

    const width = this.cubemapCanvasContainerRef.nativeElement.clientWidth;
    const height = this.cubemapCanvasContainerRef.nativeElement.clientHeight;

    this.renderer.setSize(width, height);
    this.css3dRenderer.setSize(width, height);
  }

  /**
   * Load the cubemap texture from the provided face map.
   * If loading the cube texture failed, set the appropriate signal to `true` to notify the user.
   *
   * @returns Promise that resolves when the background is set.
   */
  private setSceneBackground(): Promise<void> {
    return new Promise<void>(resolve => {
      if (!this.scene) {
        return resolve();
      }

      this.scene.background = new CubeTextureLoader()
        // Since the texture loader will use <img> tags for loading the different faces,
        // We need to ensure that the cookie authentication headers are properly set.
        .setCrossOrigin('use-credentials')
        .setWithCredentials(true)
        .load(
          [
            this.faceMap.px,
            this.faceMap.nx,
            this.faceMap.py,
            this.faceMap.ny,
            this.faceMap.pz,
            this.faceMap.nz
          ],
          () => {
            this.backgroundLoadingStatusSig.set('success');
            resolve();
          },
          progress => this.backgroundLoadingProgressSig.set(Math.round(progress.loaded / progress.total * 100)),
          () => {
            this.backgroundLoadingStatusSig.set('failed');
            resolve();
          }
        );
    });
  }
}
