export enum TrackingEventType {
  AREA_VIEW = 'AREA_VIEW',                           // When user opens an area
  AREA_ELEMENT_VIEW = 'AREA_ELEMENT_VIEW',           // When user views/focuses an area element
  AREA_ELEMENT_CLICK = 'AREA_ELEMENT_CLICK',         // When user clicks an area element
  FILE_DOWNLOAD = 'FILE_DOWNLOAD',                   // When user downloads a file
  FILE_VIEW = 'FILE_VIEW',                           // When user views a file (images, videos, etc.)
  FILE_SEARCH_PERFORMED = 'FILE_SEARCH_PERFORMED',   // When user searchs for files (media library ect..)
  FORM_SUBMISSION = 'FORM_SUBMISSION',               // When user submits a form
  MEDIA_LIBRARY_OPEN = 'MEDIA_LIBRARY_OPEN',         // When user opens media library
  VIDEO_PLAY = 'VIDEO_PLAY',                         // When user plays a video
  VIDEO_COMPLETE = 'VIDEO_COMPLETE',                 // When user completes watching a video
  WEBSITE_LINK_CLICK = 'WEBSITE_LINK_CLICK',         // When user clicks external website links
  AREA_NAVIGATION = 'AREA_NAVIGATION',               // When user navigates between areas
  POPUP_OPEN = 'POPUP_OPEN',                         // When modals/popups are opened
  POPUP_CLOSE = 'POPUP_CLOSE'                        // When modals/popups are closed
}
