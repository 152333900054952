import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, effect, signal } from '@angular/core';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';

import { ButtonTextSize, ButtonFontWeight } from '../button/button.component';
import { IconComponent, IconFillColor } from '../../icon/icon.component';

export type ActionButton = {
  id: string;
  icon?: string;
  label?: string;
  buttonType?: ButtonType;
  tooltipText?: string;
  role?: ActionRole;
  disabled?: boolean;
};

export type ActionButtonEvent<T = any> = {
  actionButton: ActionButton;
  value?: T;
};

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent],
  selector: 'nrc-action-button-menu-item',
  templateUrl: './action-button-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonMenuItemComponent implements OnChanges {
  @Input() public disabled?: boolean;
  @Input() public icon?: string;
  @Input() public label?: string;
  @Input() public textSize?: ButtonTextSize = 'sm';
  @Input() public fontWeight?: ButtonFontWeight = 'normal';
  @Input() public iconTooltipText?: string;
  @Input() public role?: ActionRole;
  @Input() public align?: string = 'center';
  @Input() public classList?: string = '';

  @Output() public clickEvent = new EventEmitter<void>();

  public actionRole = ActionRole;
  public buttonTypeActive = ButtonType;

  public isHovered = signal<boolean>(false);

  public iconFillColor = signal<IconFillColor>('white');

  /**
   * @constructor
   */
  constructor() {
    // Update the icon fill color, if the hover state of the button changed.
    effect(() => {
      this.isHovered();

      this.updateIconFillColor();
    }, { allowSignalWrites: true });
  }

  /**
   *
   */
  public ngOnChanges(): void {
    this.updateIconFillColor();
  }

  /**
   *
   */
  public handleItemActionButtonClickEvent(): void {
    this.clickEvent.emit();
  }

  /**
   * Update the icon fill color based on the button's state.
   */
  private updateIconFillColor(): void {
    switch (this.role) {
      case ActionRole.PRIMARY:
        this.iconFillColor.set('black');
        break;
      case ActionRole.SECONDARY:
      case ActionRole.TRANSPARENT_SECONDARY:
        this.iconFillColor.set(this.isHovered() ? 'cyan' : 'white');
        break;
      case ActionRole.TRANSPARENT_PRIMARY:
        this.iconFillColor.set('cyan');
        break;
      case ActionRole.ERROR:
        this.iconFillColor.set(this.isHovered() ? 'error' : 'white');
        break;
      case ActionRole.WARNING:
        this.iconFillColor.set(this.isHovered() ? 'warning' : 'white');
        break;
      case ActionRole.SUCCESS:
        this.iconFillColor.set(this.isHovered() ? 'success' : 'white');
        break;
      default:
        break;
    }
  }
}
