import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { ButtonType, ActionRole } from '@newroom-connect/library/enums';
import { ArrayHelper } from '@newroom-connect/library/helpers';

import { ButtonComponent } from '../buttons/button/button.component';
import { ModalTitleSize } from '../modal/modal.component';
import { IconComponent } from '../icon/icon.component';
import { DestroyableComponent } from '../abstract/destroyable/destroyable.component';
import { InputLanguageSelectorComponent, InputLanguageSelectorComponentType } from '../inputs/input-language-selector/input-language-selector.component';

interface IAutoTranslateForm {
  from: FormControl<string>;
  to: FormControl<string>;
}

export interface ITranslateModalParams {
modalTitle: string;
modalDescription: string;
modalFieldsDescription: string;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconComponent,
    ButtonComponent,
    InputLanguageSelectorComponent
  ],
  selector: 'nrc-auto-translate',
  templateUrl: './auto-translate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoTranslateComponent extends DestroyableComponent implements OnInit {
  @Input({ required: true }) public selectedLanguageCode!: string;
  @Input({ required: true }) public translationModalParams = signal<ITranslateModalParams>({
    modalTitle: '',
    modalDescription: '',
    modalFieldsDescription: ''
  });

  @Input() public languageCodes: string[] = [];
  @Input() public inputsToTranslate: { [key: string]: string } = {};

  @Output() public autoTranslateResult = new EventEmitter<{ from: string, to: string }>();
  @Output() public closeEvent = new EventEmitter<void>();

  public autoTranslateFormGroup = new FormGroup<IAutoTranslateForm>({
    to: new FormControl<string>('', { nonNullable: true }),
    from: new FormControl<string>('', { nonNullable: true })
  });

  public isTranslateModalOpen = false;
  public sourceLanguageCodes: string[] = [];
  public targetLanguageCodes: string[] = [];

  public buttonType = ButtonType;
  public actionRole = ActionRole;
  public inputLanguageSelectorComponentType = InputLanguageSelectorComponentType;
  public modalTitleSize = ModalTitleSize;

  constructor() {
    super();
  }

  /**
   *
   */
  public ngOnInit(): void {
    this.sourceLanguageCodes = [...this.languageCodes];
    this.targetLanguageCodes = [...this.languageCodes];

    if (!ArrayHelper.isNotEmpty(this.sourceLanguageCodes) || !ArrayHelper.isNotEmpty(this.targetLanguageCodes)) {
      return;
    }

    this.autoTranslateFormGroup.patchValue({ from: this.selectedLanguageCode, to: this.targetLanguageCodes.at(0) });

    this.registerSourceLanguageCodeFormChange();
  }

  /**
   * Sends back the selected language codes (from & to).
   *
   */
  public async handleAutoTranslateEvent(): Promise<void> {
    this.autoTranslateResult.emit({
      from: this.autoTranslateFormGroup.controls.from.value,
      to: this.autoTranslateFormGroup.controls.to.value
    });
  }

  /**
   * Watches the changes in from languageCode and sets the to languageCode.
   */
  private registerSourceLanguageCodeFormChange(): void {
    this.autoTranslateFormGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.sourceLanguageCodes = [...this.languageCodes.filter(code => code !== value.to)];
        this.targetLanguageCodes = [...this.sourceLanguageCodes.filter(code => code !== value.from)];
      });
  }
}
