<span
  class="max-w-36 p-0.5 block text-sm text-ellipsis text-nowrap whitespace-nowrap overflow-hidden focus:text-clip"
  #spanInput
  nrcContenteditable
  [value]="formControl.value"
  [defaultValue]="default"
  (valueChanged)="valueChanged($event)"
>
  {{ formControl.value }}
</span>
