import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, effect, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICrossAppMessageData, SupportedCrossAppEventType, ICrossAppMessage, IFile } from '@newroom-connect/library/interfaces';
import { FileHelper } from '@newroom-connect/library/helpers';
import { CrossAppMessageService } from '@newroom-connect/library/services';

import { HTMLOverlay } from '../../cubemap/cubemap.component';
import { LoadingSpinnerComponent } from '../../loading/loading-spinner/loading-spinner.component';

import { AreaBackground2DComponent } from './area-background-2d/area-background-2d.component';
import { AreaBackground360DegreeComponent } from './area-background-360-degree/area-background-360-degree.component';
import { AreaBackgroundEmptyComponent } from './area-background-empty/area-background-empty.component';
import { HtmlBackgroundComponent } from './html-background/html-background.component';

@Component({
  selector: 'nrc-area-background',
  standalone: true,
  imports: [
    CommonModule,
    AreaBackground2DComponent,
    AreaBackground360DegreeComponent,
    AreaBackgroundEmptyComponent,
    HtmlBackgroundComponent,
    LoadingSpinnerComponent
  ],
  templateUrl: './area-background.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AreaBackgroundComponent implements OnChanges {
  @Input({ required: true }) public areaElements: HTMLOverlay[] = [];
  @Input({ required: true }) public apiBaseUrl = '';
  @Input() public backgroundFile?: IFile;
  @Input() public isEditMode = true;

  @Output() public changeBackgroundEvent = new EventEmitter<void>();
  @Output() public backgroundActionEvent = new EventEmitter<ICrossAppMessageData>();
  @Output() public loadComplete = new EventEmitter<void>();

  public isBackgroundFileCubemapSig = signal<boolean>(false);
  public isBackgroundFileHTMLSig = signal<boolean>(false);
  public isLoading = signal<boolean>(false);

  public isBackgroundActionEventActive = false;

  /**
   * @constructor
   *
   * @param crossAppMessageService
   */
  constructor(private readonly crossAppMessageService: CrossAppMessageService) {
    effect(() => {
      const isBackgroundFileHtml = this.isBackgroundFileHTMLSig();

      // Only register the background html action events if not in edit mode and the background is a html file.
      if (isBackgroundFileHtml && !this.isEditMode && !this.isBackgroundActionEventActive) {
        this.isBackgroundActionEventActive = true;
        this.crossAppMessageService.registerHandler(SupportedCrossAppEventType.HTML_BACKGROUND, this.handleBackgroundActionEvent.bind(this));
      }
    });
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['backgroundFile']) {
      // Set loading to true when background file changes
      this.isLoading.set(true);

      this.isBackgroundFileCubemapSig.set(changes['backgroundFile'].currentValue ? FileHelper.isFileCubemap(changes['backgroundFile'].currentValue) : false);
      this.isBackgroundFileHTMLSig.set(changes['backgroundFile'].currentValue ? FileHelper.isFileHTML(changes['backgroundFile'].currentValue) : false);
    }
  }

  /**
   * Handle background load completion.
   */
  public onBackgroundLoadComplete(): void {
    this.isLoading.set(false);
    this.loadComplete.emit();
  }

  /**
   *
   * @param event
   */
  private handleBackgroundActionEvent(event: ICrossAppMessage): void {
    if (event.type !== SupportedCrossAppEventType.HTML_BACKGROUND) {
      return;
    }

    this.backgroundActionEvent.emit(event.data);
  }
}
