<div
  class="flex flex-col"
  nrcDoubleClick
  (singleClickEvent)="handleItemClickEvent(index)"
>
  <form
    class="relative w-full h-[9rem] flex items-center shadow-[0_6px_14px_#00000033] bg-[#fefefe0d] hover:bg-[#fefefe1a] rounded-10 border-2 border-solid transition-all duration-200"
    [ngClass]="{
      'border-white/5': !isSelected(),
      'border-cyan-highlight': isSelected()
    }">
    @if (isFolderCard) {
      <div class="absolute top-2 right-2 rounded-full shadow-[0_3px_6px_#00000029] bg-white/10 flex items-center justify-center hover:scale-95 transition-transform duration-250">
        <nrc-button
          class="w-8 h-8 realive"
          [icon]="'folder'"
          [buttonType]="buttonType.ICON"
          [role]="actionRole.TRANSPARENT_SECONDARY"
          [tooltipText]="'Open Card'"
          (clickEvent)="handleOpenCardEvent(index)"
        />
      </div>
    }

    <div class="w-full h-full">
      @if (thumbnailFile() || !isFolderCard) {
        <nrc-media-preview
          class="w-full h-full flex justify-center rounded-lg overflow-hidden"
          [mimetype]="thumbnailFile() ? thumbnailFile()?.mimetype ?? '' : fileItem() ? fileItem()?.mimetype ?? '' : ''"
          [source]="thumbnailFileSource() || fileItemSource()"
          [mediaPreviewTypes]="['image', 'video-snippet']"
        />
      } @else {
        <div class="w-full h-full bg-white/10 rounded-lg">
        </div>
      }

      @if (showCheckbox) {
        <nrc-checkbox class="absolute top-2 left-2" [checkStatus]="isSelected() ? 'checked' : 'unchecked'" />
      }
    </div>
  </form>

  <div class="w-full flex flex-col items-start pt-2 px-1">
    <div class="w-full flex flex-col justify-center">
      <span class="text-sm text-ellipsis overflow-hidden whitespace-nowrap" [ngClass]="{
          'text-neutral-300 font-normal': !isSelected(),
          'text-cyan-highlight font-medium': isSelected()
        }">
        {{ selectedItem ? selectedItem.label : '' }}
      </span>
    </div>
  </div>
</div>
