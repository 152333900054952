import { MediaItem } from './media-library.interface';

/**
 * Available action names.
 */
export enum ActionName {
  MOVE = 'MOVE',
  VIDEO = 'VIDEO',
  WEBSITE = 'WEBSITE',
  FORM_BUILDER = 'FORM_BUILDER',
  MEDIA_LIBRARY = 'MEDIA_LIBRARY',
  WYSIWYG = 'WYSIWYG'
}

/**
 * Video type options.
 */
export enum VideoType {
  FILE = 'FILE',
  YOUTUBE = 'YOUTUBE'
}

/**
 * Base interface for all action values.
 */
export interface BaseActionValue {
  popupTitle?: string;
  [key: string]: unknown;
}

/**
 * Move action value type.
 */
export interface MoveActionValue extends BaseActionValue {
  moveAreaId: string;
}

/**
 * Video action value type.
 */
export interface VideoActionValue extends BaseActionValue {
  videoType: VideoType;
  videoFileId?: string;
  youtubeUrl?: string;
}

/**
 * Website action value type.
 */
export interface WebsiteActionValue extends BaseActionValue {
  websiteUrl: string;
  openInNewTab?: boolean;
}

/**
 * Form builder action value type.
 */
export interface FormBuilderActionValue extends BaseActionValue {
  formBuilderData: any;
}

/**
 * Media library action value type.
 */
export interface MediaLibraryActionValue extends BaseActionValue {
  mediaLibraryData: MediaItem[]
}

/**
 * WYSIWYG action value type.
 */
export interface WysiwygActionValue extends BaseActionValue {
  wysiwygContent: string;
}

/**
 * Type helper for getting the value type based on action name.
 *
 * @template T - Action name to get the value type for.
 *
 * @returns The corresponding value interface for the action.
 */
export type ActionValueType<T extends ActionName> =
  T extends 'MOVE' ? MoveActionValue :
  T extends 'VIDEO' ? VideoActionValue :
  T extends 'WEBSITE' ? WebsiteActionValue :
  T extends 'FORM_BUILDER' ? FormBuilderActionValue :
  T extends 'MEDIA_LIBRARY' ? MediaLibraryActionValue :
  T extends 'WYSIWYG' ? WysiwygActionValue :
  never;

/**
 * Type guard to check if an action value matches its expected type.
 *
 * @param name - The action name.
 * @param value - The value to check.
 *
 * @returns Whether the value matches the expected type.
 */
export const isValidActionValue = <T extends ActionName>(name: T, value: unknown): value is ActionValueType<T> => {
  switch (name) {
    case 'MOVE':
      return typeof (value as MoveActionValue).moveAreaId === 'string';

    case 'VIDEO': {
      const videoValue = value as VideoActionValue;

      return (videoValue.videoType === VideoType.FILE || videoValue.videoType === VideoType.YOUTUBE)
        && ((videoValue.videoType === VideoType.FILE && typeof videoValue.videoFileId === 'string') || (videoValue.videoType === VideoType.YOUTUBE && typeof videoValue.youtubeUrl === 'string'));
    }

    case 'WEBSITE': {
      const webValue = value as WebsiteActionValue;

      return typeof webValue.websiteUrl === 'string';
    }

    case 'FORM_BUILDER':
      return typeof (value as FormBuilderActionValue).formBuilderData !== 'undefined';

    case 'MEDIA_LIBRARY': {
      const mediaValue = value as MediaLibraryActionValue;

      return Array.isArray(mediaValue.mediaLibraryData);
    }

    case 'WYSIWYG':
      return typeof (value as WysiwygActionValue).wysiwygContent === 'string';

    default:
      return false;
  }
};

/**
 * Create an initial value object for a given action type.
 *
 * @param name - The action name to create an initial value for.
 *
 * @returns A properly typed initial value object for the given action.
 */
export const createInitialActionValue = <T extends ActionName>(name: T): ActionValueType<T> => {
  switch (name) {
    case 'MOVE':
      return {
        moveAreaId: ''
      } as ActionValueType<T>;

    case 'VIDEO':
      return {
        videoType: VideoType.FILE,
        videoFileId: '',
        popupTitle: ''
      } as ActionValueType<T>;

    case 'WEBSITE':
      return {
        websiteUrl: '',
        openInNewTab: false,
        popupTitle: ''
      } as ActionValueType<T>;

    case 'FORM_BUILDER':
      return {
        formBuilderData: {},
        popupTitle: ''
      } as ActionValueType<T>;

    case 'MEDIA_LIBRARY':
      return {
        mediaLibraryData: [] as MediaItem[]
      } as ActionValueType<T>;

    case 'WYSIWYG':
      return {
        wysiwygContent: '',
        popupTitle: ''
      } as ActionValueType<T>;

    default:
      return {} as ActionValueType<T>;
  }
};
