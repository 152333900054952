  <div class="w-full flex justify-between items-center space-x-2">
    @if (isLabelEnabled) {
      <div class="flex justify-between items-center w-full">
        @if (label) {
          <span class="text-sm tracking-wide">{{ label }}</span>
        }
        <div class="flex items-center space-x-2">
          <input
            type="number"
            [min]="minLimit"
            [max]="maxLimit"
            [formControl]="minControl"
            (change)="onMinRangeChange($event)"
            class="w-14 h-6 p-2 text-xs rounded bg-white/10 border border-gray-700 focus:outline-none focus:border-cyan-500"
          />
          <span class="text-sm text-gray-400">to</span>
          <input
            type="number"
            [min]="minLimit"
            [max]="maxLimit"
            [formControl]="maxControl"
            (change)="onMaxRangeChange($event)"
            class="w-14 h-6 p-2 text-xs rounded bg-white/10 border border-gray-700 focus:outline-none focus:border-cyan-500"
          />
        </div>
      </div>
    }
  </div>
  <div class="relative mt-2 h-2">
    <div class="absolute w-full h-0.5 bg-gray-900 rounded">
      <div
        class="absolute h-full bg-cyan-500 rounded"
        [style.left]="((minControl.value - minLimit) / (maxLimit - minLimit) * 100) + '%'"
        [style.right]="(100 - (maxControl.value - minLimit) / (maxLimit - minLimit) * 100) + '%'"
      ></div>
    </div>
    <input
      #minRangeElement
      type="range"
      [min]="minLimit"
      [max]="maxLimit"
      [formControl]="minControl"
      (input)="onMinRangeInput($event)"
      class="absolute w-full appearance-none bg-transparent pointer-events-none h-1/2
        [&::-webkit-slider-thumb]:appearance-none
        [&::-webkit-slider-thumb]:w-4
        [&::-webkit-slider-thumb]:h-4
        [&::-webkit-slider-thumb]:rounded-full
        [&::-webkit-slider-thumb]:bg-[#00cfff]
        [&::-webkit-slider-thumb]:opacity-100
        [&::-webkit-slider-thumb]:pointer-events-auto
        [&::-webkit-slider-thumb]:cursor-pointer
        [&::-webkit-slider-thumb]:hover:bg-cyan-400"
    />
    <input
      #maxRangeElement
      type="range"
      [min]="minLimit"
      [max]="maxLimit"
      [formControl]="maxControl"
      (input)="onMaxRangeInput($event)"
      class="absolute w-full appearance-none bg-transparent pointer-events-none h-1/2
        [&::-webkit-slider-thumb]:appearance-none
        [&::-webkit-slider-thumb]:w-4
        [&::-webkit-slider-thumb]:h-4
        [&::-webkit-slider-thumb]:rounded-full
        [&::-webkit-slider-thumb]:bg-[#00cfff]
        [&::-webkit-slider-thumb]:opacity-100
        [&::-webkit-slider-thumb]:pointer-events-auto
        [&::-webkit-slider-thumb]:cursor-pointer
        [&::-webkit-slider-thumb]:hover:bg-cyan-400"
    />
  </div>
