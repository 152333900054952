import { Directive, ElementRef, EventEmitter, HostListener, Output, Input, OnChanges, SimpleChanges, inject } from '@angular/core';

export interface IContentEditableChangeEvent {
  name: string;
  isFocused: boolean;
  saveChanges: boolean;
}

@Directive({
  selector: '[nrcContenteditable]',
  standalone: true
})
export class ContentEditableDirective implements OnChanges {
  @Input() public value = '';
  @Input() public defaultValue = '';

  @Output() public valueChanged = new EventEmitter<IContentEditableChangeEvent>();

  private isFocused = false;
  private readonly elementRef = inject(ElementRef);

  /**
   * Handles changes to input bindings.
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes || !changes['value']) {
      return;
    }

    this.updateElementText(changes['value'].currentValue);
  }

  /**
   * Updates the element's text content and resets cursor position.
   *
   * @param value
   */
  private updateElementText(value: string): void {
    this.elementRef.nativeElement.innerText = value;

    this.resetCursorPosition();
  }

  /**
   * Sets the cursor position to the start of the contenteditable element
   * and ensures proper scroll position with text-overflow: ellipsis.
   */
  private resetCursorPosition(): void {
    // Wait for the next tick to ensure DOM is updated
    setTimeout(() => {
      const element = this.elementRef.nativeElement;

      // First, scroll to the start
      element.scrollLeft = 0;

      // Then set the cursor position
      const selection = window.getSelection();
      const range = document.createRange();

      // Make sure we have content before trying to select
      if (element.firstChild) {
        range.setStart(element.firstChild, 0);
        range.setEnd(element.firstChild, 0);
      } else {
        // If no text content, set range at the element itself
        range.setStart(element, 0);
        range.setEnd(element, 0);
      }

      selection?.removeAllRanges();
      selection?.addRange(range);

      // Force scroll to start again after selection
      element.scrollLeft = 0;
    }, 0);
  }

  /**
   * Emits change event with current value and state.
   *
   * @param save
   */
  private emitChangeEvent(save: boolean): void {
    const value = this.elementRef.nativeElement.innerText;
    const event: IContentEditableChangeEvent = {
      name: value,
      isFocused: this.isFocused,
      saveChanges: save
    };

    this.valueChanged.emit(event);
  }

  /**
   * @hostListener blur
   * Handles element blur event:
   * - Disables contenteditable
   * - Updates focus state
   * - Resets cursor position
   * - Emits save event
   */
  @HostListener('blur')
  public onBlur(): void {
    const element = this.elementRef.nativeElement;
    const textValue = element.innerText.replace(/\r?\n|\r/g, '');

    if (!textValue || textValue.length === 0) {
      this.updateElementText(this.defaultValue);
      this.emitChangeEvent(true);
    }

    element.contentEditable = false;
    this.isFocused = false;

    this.resetCursorPosition();
    this.emitChangeEvent(true);
  }

  /**
   * @hostListener input
   * Handles element input event:
   * - Emits change event without save flag
   */
  @HostListener('input')
  public onInput(): void {
    this.emitChangeEvent(false);
  }

  /**
   * @hostListener dblclick
   * Handles double-click event:
   * - Enables contenteditable
   * - Sets focus on element
   * - Updates focus state
   */
  @HostListener('dblclick')
  public onDblClick(): void {
    this.elementRef.nativeElement.contentEditable = true;

    this.elementRef.nativeElement.focus();
    this.isFocused = true;
  }

  /**
   * @param event - Keyboard event.
   * @hostListener keydown.enter
   * Handles enter key press:
   * - Prevents new line insertion
   * - Triggers blur to save changes
   */
  @HostListener('keydown.enter', ['$event'])
  public onEnter(event: KeyboardEvent): void {
    // Prevent multiple lines of input and emulate confirmation on enter press.
    event.preventDefault();
    this.elementRef.nativeElement.blur();
  }
}
