import { Directive, EventEmitter, HostListener, Output, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, buffer, debounceTime, filter, map } from 'rxjs';

export type DoubleClickEvent = MouseEvent;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[nrcDoubleClick]',
  standalone: true
})
export class DoubleClickDirective implements OnInit, OnDestroy {
  @Output() public doubleClickEvent = new EventEmitter<DoubleClickEvent>();
  @Output() public singleClickEvent = new EventEmitter<MouseEvent>();

  private readonly click$ = new Subject<{ event: MouseEvent; timestamp: number }>();
  private readonly DOUBLE_CLICK_DELAY = 250;
  private subscription?: Subscription;

  /**
   * Listens for click events on the host element and emits them to the click$ subject.
   *
   * @param event The MouseEvent triggered by clicking the element.
   */
  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.click$.next({ event, timestamp: Date.now() });
  }

  /**
   * Initializes the double click detection logic using RxJS operators.
   * First, Buffer collects clicks within the specified time window.
   * Then, Filter ensures we only process pairs of clicks.
   * Finally, Map extracts the second click event for emission.
   *
   */
  public ngOnInit(): void {
    const doubleClickStream$ = this.click$.pipe(
      buffer(this.click$.pipe(debounceTime(this.DOUBLE_CLICK_DELAY))),
      filter((clicks) => clicks.length === 2),
      map(([secondClick]) => secondClick.event)
    );

    const singleClickStream$ = this.click$.pipe(
      buffer(this.click$.pipe(debounceTime(this.DOUBLE_CLICK_DELAY))),
      filter((clicks) => clicks.length === 1),
      map(([singleClick]) => singleClick.event)
    );

    this.subscription = doubleClickStream$.subscribe((event) => {
      this.doubleClickEvent.emit(event);
    });

    this.subscription.add(
      singleClickStream$.subscribe((event) => {
        this.singleClickEvent.emit(event);
      })
    );
  }

  /**
   * Cleans up the click$ subject to prevent memory leaks.
   *
   */
  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.click$.complete();
  }
}
