import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceInformationService {
  // Device maps
  public iosDeviceMapping = new Map([
    ['320x480', 'IPhone 4S, 4, 3GS, 3G, 1st gen'],
    ['320x568', 'IPhone 5, SE 1st Gen,5C, 5S'],
    ['375x667', 'IPhone SE 2nd Gen, 6, 6S, 7, 8'],
    ['375x812', 'IPhone X, XS, 11 Pro, 12 Mini, 13 Mini'],
    ['390x844', 'IPhone 13, 13 Pro, 12, 12 Pro'],
    ['414x736', 'IPhone 8+'],
    ['414x896', 'IPhone 11, XR, XS Max, 11 Pro Max'],
    ['428x926', 'IPhone 13 Pro Max, 12 Pro Max'],
    ['476x847', 'IPhone 7+, 6+, 6S+'],
    ['744x1133', 'IPad Mini 6th Gen'],
    [
      '768x1024',
      'IPad Mini (5th Gen), IPad (1-6th Gen), iPad Pro (1st Gen 9.7), Ipad Mini (1-4), IPad Air(1-2)  '
    ],
    ['810x1080', 'IPad 7-9th Gen'],
    ['820x1180', 'iPad Air (4th gen)'],
    ['834x1194', 'iPad Pro (3-5th Gen 11)'],
    ['834x1112', 'iPad Air (3rd gen), iPad Pro (2nd gen 10.5)'],
    ['1024x1366', 'iPad Pro (1-5th Gen 12.9)']
  ]);

  public osPatterns: { [key: string]: RegExp } = {
    Windows: /Windows NT ([\d.]+)/,
    macOS: /Mac OS X ([\d._]+)/,
    Linux: /Linux/,
    Android: /Android ([\d.]+)/,
    iOS: /iPhone|iPad|iPod.*OS ([\d_]+)/
  };

  public browserPatterns: { [key: string]: RegExp } = {
    Chrome: /Chrome\/([\d.]+)/,
    Firefox: /Firefox\/([\d.]+)/,
    Safari: /Safari\/([\d.]+)$/,
    Edge: /Edg\/([\d.]+)/,
    Opera: /OPR\/([\d.]+)/
  };

  /**
   * Get device name utility.
   *
   * @returns
   */
  public getDeviceName(): string {
    // Check if mobile device
    const isMobileDevice = window.navigator.userAgent.toLowerCase().includes('mobi');

    if (!isMobileDevice) {
      return this.getDesktopDeviceName();
    }

    return window.navigator.userAgent.includes('Android') ? this.getAndroidDeviceName() : this.getIosDeviceName();
  }

  /**
   * Get used browser.
   *
   * @returns
   */
  public getBrowser(): string {
    let browser = 'Unknown Browser';
    let browserVersion = '';

    for (const [name, pattern] of Object.entries(this.browserPatterns)) {
      const match = navigator.userAgent.match(pattern);

      if (match) {
        browser = name;
        browserVersion = match[1] ?? '';

        break;
      }
    }

    return `${browser} ${browserVersion}`;
  }

  /**
   * Get device name for android.
   *
   * @returns
   */
  private getAndroidDeviceName(): string {
    const androidUserAgentString = window.navigator.userAgent.slice(window.navigator.userAgent.indexOf('Android'));
    const androidDeviceName = androidUserAgentString.slice(androidUserAgentString.indexOf('; ') + 1, androidUserAgentString.indexOf(')'));

    if (androidDeviceName) {
      return androidDeviceName.trim().split(' ')[0];
    }

    return 'Android';
  }

  /**
   * Get device name for ios.
   *
   * @returns
   */
  private getIosDeviceName(): string {
    const screenResolution = `${window.screen.width}x${window.screen.height}`;
    const device = this.iosDeviceMapping.get(screenResolution);

    return device ?? 'IPhone';
  }

  /**
   * Get device name for desktop.
   *
   * @returns
   */
  private getDesktopDeviceName(): string {
    let os = 'Unknown OS';
    let osVersion = '';

    for (const [name, pattern] of Object.entries(this.osPatterns)) {
      const match = navigator.userAgent.match(pattern);

      if (match) {
        os = name;
        osVersion = match[1] ? match[1].replace(/_/g, '.') : ''; // Replace underscores in versions

        break;
      }
    }

    return `${os} ${osVersion}`;
  }
}
