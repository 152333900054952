@if (!fileLibraryModalOnly) {
  <div
    class="w-full flex flex-row justify-center items-center"
    [ngClass]="{
      'gap-4': size === inputFileComponentSize.REGULAR,
      'gap-2': size === inputFileComponentSize.SMALL,
      'opacity-50 select-none pointer-events-none': disabled
    }"
  >
    <!-- The background thumbnail container -->
    <div
      class="group relative flex shrink-0 justify-center items-center rounded-10 overflow-hidden cursor-pointer"
      [ngClass]="{
        'animate-rotate-bounce': animateFilePreview(),
        'w-16 h-16': size === inputFileComponentSize.SMALL,
        'w-24 h-24': size === inputFileComponentSize.REGULAR
      }"
    >
      <div
        class="absolute top-0 left-0 w-full h-full rounded-10 bg-white/75 group-hover:opacity-50 transition-all duration-250"
        tabindex="0"
        (click)="toggleFileLibraryModalVisibility()"
        (keydown)="null"
      ></div>

      @if (formControl.value) {
        <nrc-media-preview
          class="absolute top-0 left-0 w-full h-full object-cover group-hover:opacity-50 transition-all duration-250"
          [mimetype]="mimetype ?? 'image/'"
          [source]="backgroundFileUrlFormControl.value ? backgroundFileUrlFormControl.value : ''"
          [mediaPreviewTypes]="['image', 'video-snippet']"
          (click)="toggleFileLibraryModalVisibility()"
        />
      } @else {
        <nrc-icon
          class="absolute top-1/3 left-1/3 w-1/3 h-1/3 group-hover:opacity-100 transition-all duration-250"
          [name]="'file-type-image'"
          [fillColor]="'black'"
        />
      }

      <!-- Upload overlay -->
      <nrc-icon
        class="absolute -top-1/3 left-1/3 w-1/3 h-1/3 group-hover:top-1/3 transition-all duration-250"
        [name]="'upload'"
        (clickEvent)="toggleFileLibraryModalVisibility()"
      />
    </div>

    <div
      class="w-full grow flex flex-col"
      [ngClass]="{
        'justify-between gap-2 md-auto': size === inputFileComponentSize.REGULAR,
        'flex-wrap content-end h-16': size === inputFileComponentSize.SMALL
      }"
    >
      @switch (size) {
        @case (inputFileComponentSize.SMALL) {
          <div class="w-full">
            <nrc-input-text
              class="w-full text-xs pt-6"
              [formControl]="backgroundFileTitleFormControl"
              [label]="label"
              [placeholder]="placeholder"
              [classList]="['bg-[#2F2F2F]', 'pr-3']"
              [type]="'text'"
              [name]="'area-detail-background'"
              [id]="'area-detail-background'"
              [clipboardEnabled]="false"
              [clearEnabled]="false"
              [readonly]="true"
              [disabled]="true"
            />

            <div class="w-full">
              @if (currentFile()) {
                <span class="text-white/50 text-xs font-light">
                  {{ currentFile() | extractProperties: [{ key: 'extension', format: objectPropertyFormat.TEXT }, { key: 'createdAt', format: objectPropertyFormat.DATE}, { key: 'size', format: objectPropertyFormat.FILE_SIZE }] | join: ' | ' }}
                </span>
              } @else {
                <span class="text-white/50 text-xs font-light">
                  Please select or upload a file.
                </span>
              }

            </div>
          </div>
        }
        @case (inputFileComponentSize.REGULAR) {
          <nrc-input-text
            class="w-full text-sm"
            [formControl]="backgroundFileTitleFormControl"
            [type]="'text'"
            [name]="'area-detail-background'"
            [id]="'area-detail-background'"
            [label]="label"
            [clipboardEnabled]="false"
            [clearEnabled]="false"
            [readonly]="true"
            [disabled]="true"
          />

          <div class="w-full flex items-center space-x-4">
            <nrc-button
              [label]="uploadButtonLabel ??'PAGES.AREA_DETAIL.AREA_DETAIL_SETTINGS.INPUTS.FILE_EXPLORER.LABEL' | translate"
              [role]="actionRole.SECONDARY"
              (clickEvent)="toggleFileLibraryModalVisibility()"
            />
          </div>
        }
      }
    </div>
  </div>
}

@if (isFileLibraryModalVisibleSig().isVisible) {
  <nrc-file-library-modal
    @fadeInOutAnimation
    [currentFile]="currentFile()"
    [acceptableMimeTypes]="acceptableMimeTypes()"
    [fileListItemsSig]="fileListInputSig"
    [metaSig]="fileListMetaSig"
    [isFileListLoading]="isFileListLoading"
    [hideSelection]="isFileLibraryModalVisibleSig().hideSelection"
    (uploaderFilesChanged)="null"
    (initEvent)="isFileLibraryModalVisibleSig().hideSelection ? handleFileListOptions() : null"
    (showFileLibraryEvent)="handleFileListOptions()"
    (fileUploadEvent)="handleFileLibraryModalFileUploadEvent($event)"
    (fileListEvent)="handleFileListOptions($event)"
    (fileSearchEvent)="handleFileSearchEvent($event)"
    (closeEvent)="handleFileLibraryModalCloseEvent($event)"
  />
}
