import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnChanges, signal, SimpleChanges } from '@angular/core';
import { AspectRatioDirective } from '@newroom-connect/library/directives';

import { ImageComponent } from '../image/image.component';
import { VideoComponent } from '../video/video.component';
import { AudioComponent } from '../audio/audio.component';
import { FileTypeComponent } from '../files/file-type/file-type.component';

export type MediaPreviewType = 'audio' | 'video' | 'video-snippet' | 'image' | 'generic';

@Component({
  standalone: true,
  imports: [
    ImageComponent,
    VideoComponent,
    AudioComponent,
    FileTypeComponent,
    AspectRatioDirective
  ],
  selector: 'nrc-media-preview',
  templateUrl: './media-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaPreviewComponent implements OnChanges {
  @Input({ required: true }) public mimetype = '';
  @Input({ required: false }) public source?: string;
  @Input() public objectFit: 'cover' | 'contain' | 'fill' = 'cover';
  @Input() public classList = '';
  @Input() public mediaPreviewTypes?: MediaPreviewType[];
  @Output() public loadComplete = new EventEmitter<void>();

  public mediaPreviewType = signal<MediaPreviewType>('generic');

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['source']) {
      this.mediaPreviewType.set(this.getMediaPreviewTypeFromMimetype(this.mimetype));
    }
  }

  /**
   *
   * @param mimetype
   *
   * @returns
   */
  private getMediaPreviewTypeFromMimetype(mimetype: string): MediaPreviewType {
    let mediaPreviewType: MediaPreviewType = 'generic';

    if (mimetype.startsWith('audio/') && this.isMediaPreviewTypeEnabled('audio')) {
      mediaPreviewType = 'audio';
    } else if (mimetype.startsWith('video/')) {
      if (this.isMediaPreviewTypeEnabled('video')) {
        mediaPreviewType = 'video';
      } else if (this.isMediaPreviewTypeEnabled('video-snippet')) {
        mediaPreviewType = 'video-snippet';
      }
    } else if (mimetype.startsWith('image/') && this.isMediaPreviewTypeEnabled('image')) {
      mediaPreviewType = 'image';
    }

    return mediaPreviewType;
  }

  /**
   * Check, if the given media preview type is enabled.
   * If no media preview types are available as input, all are enabled.
   *
   * @param mediaPreviewType The media preview type to check for enabled status.
   *
   * @returns Boolean indicating if the media preview type is enabled.
   */
  private isMediaPreviewTypeEnabled(mediaPreviewType: MediaPreviewType): boolean {
    if (!this.mediaPreviewTypes) {
      return true;
    }

    return this.mediaPreviewTypes.includes(mediaPreviewType);
  }
}
