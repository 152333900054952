@if (!hasLoadedData) {
  <div class="flex items-center justify-center w-full h-full">
    <nrc-loading-spinner />
  </div>
}

<img
  class="w-full h-full"
  [ngClass]="{
    'bg-transparent-dark': !isTransparent,
    'hidden': !hasLoadedData,
    'object-cover': objectFit === 'cover',
    'object-contain': objectFit === 'contain',
    'object-fill': objectFit === 'fill'
  }"
  [src]="source.uri"
  [alt]="''"
  [draggable]="false"
  (load)="onLoadComplete()"
/>
