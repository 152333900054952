import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, forwardRef, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { OverflowTargetDirective } from '@newroom-connect/library/directives';
import { ExtractPropertyPipe, HasSetValuePipe, IsNonEmptyArrayPipe, ToArrayPipe, ToStringPipe } from '@newroom-connect/library/pipes';
import { growAnimation, fadeInOutAnimation } from '@newroom-connect/library/animations';
import { ObjectHelper } from '@newroom-connect/library/helpers';

import { InputComponent } from '../input.component';
import { IconComponent } from '../../icon/icon.component';
import { ChipComponent } from '../../chip/chip.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconComponent,
    ChipComponent,
    OverflowTargetDirective,
    ExtractPropertyPipe,
    HasSetValuePipe,
    IsNonEmptyArrayPipe,
    ToArrayPipe,
    ToStringPipe
  ],
  selector: 'nrc-input-select',
  templateUrl: './input-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [growAnimation, fadeInOutAnimation],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectComponent),
      multi: true
    }
  ]
})
export class InputSelectComponent extends InputComponent implements OnInit, AfterViewInit, OnChanges {
  private static readonly SELECT_OPTIONS_MENU_MAX_HEIGHT_PX = 48;
  private static readonly INPUT_FONT_WIDTH = 10; // Since the width of the font cannot be determined, we estimate it with 10px per character.

  @ViewChild('selectInputElement') public selectInputElementRef!: ElementRef<HTMLDivElement>;
  @ViewChild('selectOptionsElement') public selectOptionsElementRef!: ElementRef<HTMLDivElement>;

  @Input() public options: unknown[] = [];
  @Input() public valueProperty?: string;
  @Input() public labelProperty?: string;
  @Input() public isTransparent = false;
  @Input() public isRound = false;
  @Input() public fitContent = false;
  @Input() public multiple = false;
  @Input() public showTags = true;
  @Input() public searchEnabled = true;
  @Input() public overflowParentElement?: HTMLElement;
  @Input() public allowAddingOptions = false;
  @Input() public addOptionPattern?: string | RegExp;

  @Output() public optionAddedEvent = new EventEmitter<unknown>();

  public showAddOption = signal<boolean>(false);

  public showOptions = false;

  public optionsFiltered: unknown[] = [];
  public selectedOptions = new Set<unknown>();

  public searchInputFormControl = new FormControl<string>('');

  public willSelectOptionsOverflowDom = signal<boolean>(false);

  /**
   * @constructor
   */
  constructor() {
    super();

    this.searchInputFormControl.valueChanges.pipe(takeUntilDestroyed()).subscribe(searchInput => {
      this.optionsFiltered = this.options.filter(option => {
        const optionLabel = (this.labelProperty ? `${ObjectHelper.extractProperty(option, this.labelProperty)}` : `${option}`);

        return optionLabel.toLowerCase().includes(searchInput?.toLowerCase() ?? '');
      });

      // Show add option button only when:
      // 1. Adding options is allowed.
      // 2. There's search input value.
      // 3. No existing option matches exactly or the options list is empty.
      if (searchInput && searchInput.length > 0 && this.allowAddingOptions) {
        const exactMatch = this.options.some(option => {
          const optionLabel = (this.labelProperty ?
            `${ObjectHelper.extractProperty(option, this.labelProperty)}` :
            `${option}`
          );

          return optionLabel.toLowerCase() === searchInput.toLowerCase();
        });

        this.showAddOption.set(!exactMatch);

        // Close list of options when show add option is visible.
        this.showOptions = exactMatch;
      }
    });
  }

  /**
   *
   */
  public override ngOnInit(): void {
    super.ngOnInit();

    if (!this.searchEnabled) {
      this.searchInputFormControl.disable();
    }

    this.optionsFiltered = this.options;
  }

  /**
   *
   */
  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    // Determine if the options menu will overflow the DOM, if the menu is displayed.
    // For this case, we will set the appropriate signal to true, to show the menu above the select input.
    const selectInputElementRefPosition = this.selectInputElementRef.nativeElement.getBoundingClientRect();

    if (selectInputElementRefPosition.bottom + InputSelectComponent.SELECT_OPTIONS_MENU_MAX_HEIGHT_PX > window.innerHeight) {
      this.willSelectOptionsOverflowDom.set(true);
    }
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    // Set the filtered options, if options have been received.
    if (changes['options'] && (!changes['options'].previousValue || changes['options'].previousValue?.length === 0) && changes['options'].currentValue?.length > 0) {
      this.optionsFiltered = changes['options'].currentValue;

      // Set the selected options based on the given form control value.
      // Necessary, if options are loaded asynchronously, but the form control value is already set.
      this.setSelectedOptions(this.formControl.value);
    }
  }

  /**
   *
   * @param value The value which is set via the injected form control element.
   */
  public override writeValue(value: unknown): void {
    this.setSelectedOptions(value);
  }

  /**
   *
   * @param option
   */
  public selectOption(option: unknown): void {
    const hasSelectedOption = this.selectedOptions.has(option);

    if (hasSelectedOption) {
      this.removeOption(option);
    } else {
      if (this.multiple) {
        this.selectedOptions.add(option);
      } else {
        this.selectedOptions = new Set();
        this.selectedOptions.add(option);
      }

      this.setFormControlValueFromSelectedOptions();
    }

    // Close the options menu for single selects after choosing an option.
    if (!this.multiple) {
      this.showOptions = false;

      // Fit input width to the length of the selected option label, if "fitContent" input is true.
      if (this.fitContent) {
        const optionLabel = (this.labelProperty ? `${ObjectHelper.extractProperty(option, this.labelProperty)}` : `${option}`);

        this.inputElementRef.nativeElement.style.width = `${optionLabel.length * InputSelectComponent.INPUT_FONT_WIDTH}px`;
      }
    }
  }

  /**
   * Adds the provided option to the selected options and options list.
   * If a pattern is provided, validates the new option against it first.
   */
  public addNewOption(): void {
    const newOptionValue = this.searchInputFormControl.value;

    if (!newOptionValue) {
      return;
    }

    // Validate against pattern if provided
    if (this.addOptionPattern) {
      const pattern = typeof this.addOptionPattern === 'string' ?
        new RegExp(this.addOptionPattern) :
        this.addOptionPattern;

      if (!pattern.test(newOptionValue)) {
        this.searchInputFormControl.setErrors({ pattern: true });
        return;
      }
    }

    // Create new option object based on labelProperty and valueProperty configuration
    let newOption: unknown;

    if (this.labelProperty && this.valueProperty) {
      // Both label and value properties are specified
      newOption = {
        [this.labelProperty]: newOptionValue,
        [this.valueProperty]: this.generateUniqueValue(newOptionValue)
      };
    } else {
      // No properties specified, use value as is
      newOption = newOptionValue;
    }

    this.options.push(newOption);

    // Emit the structured option
    this.optionAddedEvent.emit(newOption);

    // Clear the search input
    this.searchInputFormControl.setValue('');

    this.selectOption(newOption);

    this.showAddOption.set(false);
  }

  /**
   * Remove the provided option from the selected options.
   *
   * @param option The option to remove from the selected options.
   */
  public removeOption(option: unknown): void {
    this.selectedOptions.delete(option);

    this.setFormControlValueFromSelectedOptions();
  }

  /**
   * Set the selected options from the given value and the available options.
   *
   * @param value The value to set the selected options from.
   */
  private setSelectedOptions(value: unknown): void {
    const selectedOptions = this.options ? this.options.filter(option => {
      let optionExtracted = option;

      if (this.valueProperty) {
        optionExtracted = ObjectHelper.extractProperty(option, this.valueProperty);
      }

      return Array.isArray(value) ? value.includes(optionExtracted) : optionExtracted === value;
    }) : [];

    this.selectedOptions = new Set(selectedOptions);
  }

  /**
   *
   */
  private setFormControlValueFromSelectedOptions(): void {
    let selectedOptionsArray = [...this.selectedOptions];
    let selectedOptionFirst = null;

    // Extract the value property from the selected options, if a value property is specified.
    if (this.valueProperty) {
      selectedOptionsArray = selectedOptionsArray.map(option => ObjectHelper.extractProperty(option, this.valueProperty ?? ''));
    }

    if (selectedOptionsArray.length > 0) {
      selectedOptionFirst = selectedOptionsArray[0];
    }

    this.formControl.setValue(this.multiple ? [...selectedOptionsArray] : selectedOptionFirst);

    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
  }

  /**
   * Generates a unique value for new options when valueProperty is specified.
   * @param label The label to base the value on.
   * @returns A unique value string.
   */
  private generateUniqueValue(label: string): string {
    // Convert label to kebab case and lowercase
    const baseValue = label.toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]/g, '');

    // Check if this value already exists in options
    const existingValues = new Set(
      this.options
        .map(option => this.valueProperty ? ObjectHelper.extractProperty(option, this.valueProperty) : null)
        .filter(Boolean)
    );

    // If base value doesn't exist, use it
    if (!existingValues.has(baseValue)) {
      return baseValue;
    }

    // Otherwise, append numbers until we find a unique value
    let counter = 1;

    while (existingValues.has(`${baseValue}-${counter}`)) {
      counter++;
    }
    return `${baseValue}-${counter}`;
  }

  /**
   * Host listener for keyup enter events to add new option.
   *
   */
  @HostListener('keyup.enter')
  public handleEnterKey(): void {
    if (!this.showAddOption()) {
      return;
    }

    this.addNewOption();
  }
}
